.button-subSurvey {
    margin: 0em 1em 1em 0;
    width: 100%;
    background-color: #FFF;
    color: #404040;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-subSurvey:hover {
    background-color: #ED5652;
}

.goalDiv {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 2em;
}

.border-ball {
    border-radius: 50%;
    border: solid .5em #919090;
    width: 3.5em;
    height: 3.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.25em;
}

.business-summmary-title {
    text-align: center;
    /* margin-top: 1em; */
    font-size: 0.75rem;
}

.business-summary-column{
    display: flex;
    flex-direction: column;
    flex: 2 1 0;
}

.business-summary-prefix{
    align-items: center;
}

.summary-ball-font{
    font-size: 1rem;
}

.business-summary-value{
    font-size: 1rem;
    margin-top: 1.25rem;
}

.summary-tag-row{
    display: flex;
    flex-direction: row;
    flex: 2 1 0;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    max-width: 20em;
    min-width: 10em;
}

.summary-tags{
    
    margin-top: 0.15em;
}

.business-id{
    margin-top:-0.75em;
    font-size: 0.75rem;
}
