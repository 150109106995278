
  
.filter-nav .nav {
position: absolute;
top: 0;
left: 0;
bottom: 0;
width: 300px;
}

.filter-nav .background {
position: absolute;
top: 0em;
left: 3em;
bottom: 0;
width: 220px;
background: #fff;
box-shadow: 0 3px 6px #CCC;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu.disabled {
    opacity: 0.4 !important;
    pointer-events: none;
}
  
.dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
}

.filter-nav button {
outline: none;
border: none;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
cursor: pointer;
position: absolute;
top: 18px;
left: 15px;
width: 50px;
height: 50px;
border-radius: 50%;
background: transparent;
}

.filter-nav ul,
.filter-nav li {
    margin: 0;
    padding: 0;
    z-index: 5;
    font-weight: 400;
}

.filter-nav ul {
    position: absolute;
    top: 0em;
    left: 4em;
    width: 200px;
    z-index: 4;
}

.filter-nav ul ul {
    width: 300px;
}

.filter-nav li {
    list-style: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 1em 0em 1em 1em;
}

.filter-nav li:hover div .mainItem {
    opacity: 0.8 !important;
    font-weight:700;
}

.filter-nav li.subMenuItem:hover div .subItem {
    opacity: 0.8 !important;
    font-weight:700;
}

.filter-nav li.subMenuItem-disabled div .subItem {
    opacity: 0.4;
}

.filter-nav li.subMenuItem-disabled {
    pointer-events: none;
}

.dropdown-menu.show.subMenu {
    max-height: 250px;
    overflow: auto;
}

.filter-nav .icon-placeholder {
width: 40px;
height: 40px;
border-radius: 50%;
flex: 40px 0;
margin-right: 20px;
}

.filter-nav .text-placeholder {
border-radius: 5px;
width: 200px;
height: 20px;
flex: 1;
}

.filter-nav .refresh {
padding: 10px;
position: absolute;
background: rgba(0, 0, 0, 0.4);
border-radius: 10px;
width: 20px;
height: 20px;
top: 10px;
right: 10px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
}
  