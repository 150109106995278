.taskModal {
    border-bottom: 1px solid var(--highlight-color) !important;
}

.info {
    height: 25px;
    width: 25px;
    background-color: #749db4;
    border-radius: 50%;
    display: inline-block;
    margin-left: 0.8rem;
    text-align: center;
    color: #FFF;
    position: relative;
}

.info .tooltip {
    visibility: hidden;
    width: 320px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    top: -5px;
    right: 105%; 
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

.info:hover .tooltip {
    visibility: visible;
}

.taskTitle {
    height: 5rem;
}

.taskExplanation {
    height: 10rem;
    overflow: auto;
}

.taskCreatorRow .col,
.taskCreatorRow .col-xl-12, 
.taskCreatorRow .col-xl-6 {
    padding-top: 1rem;
}

.taskTreeRow {
    display: flex;
    align-items: center;
    padding-top: 1rem;
}

.taskTreeIcon {
    padding-right: 1rem;
    font-size: 1.2em;
    color: var(--highlight-color);
}
.modal-title {
    width: 100%;
}

.treeStack .taskTreeSecondRow {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    padding-left:2rem;
}

.treeStack .taskTreeLastRow {
    display: flex;
    align-items: first baseline;
    padding-top: 1rem;
    padding-left:4em;
}

.jss179 {
    color: var(--highlight-color) !important;
}

.taskCreatorFooter {
    justify-content: space-between !important;
}

.modalPageNumber {
    float:right;
    padding-right: 1rem;
}

.modalRiskColorSquare {
    height: 25px;
    width: 25px;
    align-self: center;
    margin-left: 1.5rem;
}

.tooltip .tooltiptext {
    width: 120px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

.emailAdderContainer {
    height: 45px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 0 12px 0 0;
    border-bottom: 1px solid #dddddd;
}

.emailAdderInput {
    height: 45px;
    padding: 0 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex: 1 1;
    flex: 1 1;
    outline: none;
    border: 0;
    font-size: 14px;
    background: transparent;
}

