.sidebar {
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  box-shadow: 0px 3px 10px #CACACA;
  background-color: #fff;
  position: fixed !important;
  height: 100%;
  width: 6.5rem;
}

.vertical-nav {
  height: 100%;
  text-align: center; 
  flex-wrap: nowrap;
}

.logo-trial-watermark{
  transform: rotate(-45deg);
  color:red;
  background-color: rgb(197, 197, 197);
  opacity:0.5;
  font-weight: bold;
  position:fixed;
  margin-top: -4.75em;
  width: 8em;
  margin-left: -2.75em;
}

.closeIcon {
  background-color: #FAFAFA;
  padding: 7px;
  color: rgb(125, 125, 125);
  z-index: 5;
  box-shadow: 5px 2px 4px #CACACA;
  margin-right: 2px;
}

.openPanelIcon {
  font-size: 1.5em;
  color: rgb(125, 125, 125);
  padding-right: 2rem;
  cursor: pointer;
}

.openIcon {
  box-shadow: 5px 2px 4px #CACACA;
  background-color: rgb(125, 125, 125);
  padding: 7px;
  color: #FFF;
  z-index: 5;
  cursor: pointer;
  margin-right: 3px;
}

.sidePanelCloseDiv {
  height: 100%;
  position: absolute;
  display: flex;
  width: calc(100% + 1.5rem);
  -webkit-align-items: flex-start;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.togglePanelOpenDiv {
  height: 4.2rem;
  position: absolute;
  display: flex;
  width: calc(100% + 1.5rem);
  -webkit-align-items: flex-start;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.sidePanelCloseDiv .closeIcon:hover {
  cursor: pointer;
}

.surveyMainContentRow {
  padding-top: 6em;
  line-height: 1.1em;
}

.surveyMainContentRow a.nav-link{
  padding: 0;
}

.surveyMainContentRow .col  {
  width: 100%;
  flex: 0 auto;
  padding-top: 1.05em;
  padding-bottom: 1.05em;
}

.surveyMainContentRow .col p {
  color: #404040;
  font-size: 1.1em
}

.surveyMainContentRow .col a.nav-link.active-nav {
  text-decoration: none !important;
  color: #404040 !important;
}

.contentTitle {
  cursor: pointer;
  margin-bottom:0;
}

.contentSubTitle {
  font-weight: 400;
}

.logo-container-nav-bar {
  position: relative;
  height: 6rem;
  width: 6rem;
  margin-left: 3px;
  background-size: contain;
  background-repeat: no-repeat;
}

.contentSubTitle.magic {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.4s linear;
    -moz-transition: all 0.4s linear;
          transition: all 0.4s linear;
}

.mainContentRow .col  {
  width: 100%;
  flex: 0 auto;
}

.mainContentRow .col a.nav-link {
  text-decoration: none;
  color: #404040;
}

.mainContentRow .col a.nav-link.active-nav {
  text-decoration: none !important;
  color: #404040 !important;
}

.survey-bar {
  cursor: pointer;
}

.survey-bar:hover {
  background-color: #dedede;
}

.survey-bar-active {
  background-color: #dedede;;
  color: #404040;
  cursor: pointer;
}

.sidebar .tab-content .tabContent {
  position: fixed;
  top: 0;
  left: 6.5rem;
  width: 15rem;
  height: 100%;
  background-color: #FAFAFA;
  color: #404040;
  box-shadow: 4px 3px 6px #CACACA;
}

.survey-side-bar {
  position: fixed;
  top: 0;
  left: 6.5rem;
  width: 15rem;
  height: 100%;
  background-color: #FAFAFA;
  color: #404040;
  box-shadow: 4px 3px 6px #CACACA;
}

.nav-item {
  margin: 0.5rem 0 0.5rem 0;
  width: 6.5rem;
}

.active-nav {
  color: #fff !important;
}

.nav-icon {
  font-size: 2rem;
}

.vertical-nav > .navbar-brand {
  margin-right: 0 !important;
}

.nav-logo {
  width: 70px;
  margin: 1rem 0;
}

.vertical-nav a, .vertical-nav a:hover {
  color: rgba(97,97,97,1);
}

.sidenav-active {
  background-color: rgba(125,125,125,1);
}

.sidenav-notActive {
  background-color: #fff;
}