.gpt_input{
    margin-bottom: -1em;
    /* padding-right: 0.25em; */
    border:none !important;
    height:100px !important;
}

.response-editor{
    padding: 1em;
}

.ai-button-active{
    background-color: #6ba53f;
    color: #fff;
    font-weight: bold;
}

.close-ai-block{
    font-size: 0.8rem; 
    padding: 0.15em 0.5em; 
    font-weight: bold;
    float: right;
    margin-right: 0.75em;
}

.close-ai-block:hover{
    color:red;
}

.saved-prompt-item{
    font-size: 0.9rem;
    display: block;
    overflow: hidden;
    flex-grow: 1;
    text-overflow: ellipsis;
    height: 1.5em;
    white-space: nowrap;
    max-width: 30em;
    margin-right: 1em;
}

.remove-prompt{
    margin-bottom: -1.5em;
    margin-left: auto;
    margin-right: 0em;
    float: right;
    font-weight: bold;
}

.remove-prompt:hover{
    color: red;
}