.mapping-main {
  padding: 115px 5em 0 5em !important;
}

.mapping-main:last-child .survey-main-row {
  margin-bottom: 5em;
}

.modalTextarea {
  width:100%;
}

.notifImportant {
  color: red;
}

.large-balls-column {
  width: 150px;
  margin-right: 1em;
}

.medium-balls-column {
  width: 120px;
  margin-right: 1em;
}

.comparisonIcon {
  font-size: 9em;
  color: #A6C5D5;
}


.comparisonIconCol {
  display: flex;
  align-items: center;
}

.triangle-up {
  display: flex;
  flex-direction: column;
}

.ball-container {
  position: absolute;
  left: 0;
  z-index: 3;
}

.ball-container-comparison {
  left: 0;
  z-index: 10;
  padding-top: 20px;
}

.large-score-ball {
  border: 8px solid #ffffff;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  text-align: center;
  padding-top: 30px;
  color: #ffffff;
  font-size: 2.5em;
  margin-left: 2rem;
}

.medium-score-ball {
  border: 7px solid #ffffff;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  background-color: #FFCC00;
  text-align: center;
  padding-top: 25px;
  box-shadow: 0 0 0 2pt #FFCC00;
  color: #ffffff;
  font-size: 2em;
}

.medium-score-barball {
  border: 7px solid #ffffff;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  background-color: #FFCC00;
  text-align: center;
  padding-top: 25px;
  box-shadow: 0 0 0 2pt #FFCC00;
  color: #ffffff;
  font-size: 2em;
}

.small-score-barball {
  border: 5px solid #ffffff;
  border-radius: 50%;
  height: 90px;
  width: 90px;
  background-color: #BDE533;
  text-align: center;
  padding-top: 20px;
  box-shadow: 0 0 0 2pt #BDE533;
  color: #ffffff;
  font-size: 1.5em;
}

.addTaskClear {
  background-color:#749DB4;
  color: #FFFFFF;
  border-radius:50%;
  font-size:3em;
  float:right;
  cursor: pointer;
}

.addTaskClear:hover {
  background-color: #ED5652;
}

.deadlineDate div.DateInput {
  width: 100% !important;
}
.notificationDate div.DateInput {
  width: 100% !important;
}
.employeeSelection {
  height: 48px;
}

.employeeSelectionInner__control {
  height: 100%;
}

.addTaskTitle {
  height: 48px !important;
}

.typeButton {
  height: 110px;
  width: 100%;
  color: #749DB4 !important;
  font-size: 20px !important;
  background-color: #FFFFFF !important;
  border: none !important;
  box-shadow: 0px 3px 6px  #404040;
  cursor: pointer;
}

.frontpage-task {
  display: block;
  position: relative;
  padding: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.frontpage-task.large {
  width: 30%;
  height: 300px;
  float:left;
}


.frontpage-task.small {
  width: 15%;
  height: 150px;
  font-size:0.8em;
  float:left;
}
.taskCard {
  border: none !important;
  box-shadow: 0px 3px 6px  #b3b3b3;
}

.taskFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding:15px;
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.taskFooter.small {
  font-size: 0.8em;
}

.notANumber {
  border-color: red;
}
.draftEditorInCard {
  max-height: 100px;
  min-height: 100px;
  overflow: auto;
}

.taskSurveyCardTitle {
  color: '#FFF';
  margin-bottom: 0;
  padding-bottom: 15;
  font-weight: 700
}

.iconNotVisible {
  visibility: hidden;
}

/*.taskCard.largeCard {
  height: 430px;
  min-height:430px;
}*/

.addTaskButton {
  color: #FFF;
  background-color: #749DB4 !important;
  width: 100% !important;
}

.typeButton.active {
  background-color: #749DB4 !important;
  color: #FFFFFF !important;
}

.typeButton:hover {
  background-color: #749DB4 !important;
  color: #FFFFFF !important;
}
.small-score-barball-positive {
  border: 7px solid #ffffff;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background-color: #FFCC00;
  text-align: center;
  padding-top: 5px;
  box-shadow: 0 0 0 2pt #FFCC00;
  color: #ffffff;
  font-size: 1.4em;
}

.small-score-barball-positive:before {
  content: "+";
  font-size: 1.4em;
} 

.addNote {
  cursor:pointer;
}

.barWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.small-score-barball::first-letter {
  font-size: 120%;
}

.firstToolTip.row {
  margin-top: 10px;
  display: none;
  position: absolute;
  width: 300px;
  z-index: 5;
  border-radius: 0.25em;
}

.secondToolTip.row {
  margin-top: 10px;
  display: none;
  position: absolute;
  width: 300px;
  z-index: 5;
  border-radius: 0.25em;
}

.barReportBall:hover .secondToolTip {
  display: block;
}

.barReportBall:hover .firstToolTip {
  display: block;
}
.barWrapper-comparison {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-top:15px;
}

.sentBall {
  border: 3px solid #ffffff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  padding-top: 5px;
  font-size: 0.9em;
  display: inline-block;
}

.barReportBall {
  border: 3px solid #ffffff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  padding-top: 5px;
  color: #ffffff;
  font-size: 0.9em;
  display: inline-block;
  margin-right: 10px;
  margin-top: 15px;
}
.small-score-ball {
  border: 5px solid #ffffff;
  border-radius: 50%;
  height: 90px;
  width: 90px;
  background-color: #BDE533;
  text-align: center;
  padding-top: 20px;
  box-shadow: 0 0 0 2pt #BDE533;
  color: #ffffff;
  font-size: 1.5em;
}

.smaller-score-ball {
  border: 5px solid #BDE533;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  background-color: #fff;
  text-align: center;
  padding-top: 1px;
  color: #404040;
  font-size: 0.9rem;
  font-weight: bold;
}

.small-analysis-ball {
  border: 3px solid #ffffff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #BDE533;
  text-align: center;
  padding-top: 5px;
  color: #ffffff;
  font-size: 0.9em;
  display: inline-block;
  margin-right: 10px;
  margin-top: 15px;
}

.survey-main-row {
  position: relative;
  background-color: #fff;
  margin-bottom: 0.8rem;
  padding: 2rem;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 3px 6px #CCC;
}

.survey-buttonfilters-row {
  position: relative;
  background-color: #fff;
  margin-top: 0.8rem;
  padding: 2rem;
  border-radius: 15px 15px 0 0;
  box-shadow: 0 3px 6px #CCC;
}

.report-button-filter {
  box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);
  border-radius: 5px;
  background-color: #fff;
  border: none;
  padding: 0.35rem;
  width: 100%;
  color: #777;
  margin-bottom: .5rem;
}

.report-button-filter:hover {
  background-color: var(--highlight-color);
  color: #000000;
}

.survey-title-row {
  padding-bottom: 1em;
}

.taskEditorText {
  font-weight: 600;
}
.survey-title-container {
  padding-left: 100px !important;
  margin-left: 70px;
}

.questiongroup-main-row {
  position: relative;
  margin-left: -50px;
}

.questiongroup-main-row:first-child {
  padding-top: 0 !important;
}

.questiongroup-title-container {
  padding-left: 100px;
  margin-left: 60px;
  padding-bottom: 1em;
}

.questiongroup-title-container-comparison {
  padding-left: 100px !important;
  margin-left: 60px;
  padding-bottom: 1em;
}

.questiongroup-title-row {
  padding-bottom: 1em;
  margin-bottom: 2rem;
}

.question-main-row {
  position: relative;
  margin-left: -50px;
}

.question-title-container {
  padding-left: 80px;
  margin-left: 45px;
  padding-bottom: 2em;
}

.question-title-container-comparison {
  padding-bottom: 2em;
  padding-top: 1.2em;
}

.super-border-left {
  border-left: 3px solid;
}

.SingleDatePicker {
  width: 100%;
}

.nps-arrow {
  font-size: 2em;
  position: absolute;
  top: 0em;
  right: calc(50% - 1.9em);
}

.personList {
  font-size: 1.2em !important;
  border: 2px solid #FFF !important;
  margin-left: 2em !important;
  border-radius: 50% !important;
  color: #404040 !important;
  background-color: #FFF !important;
  display: inline-block !important;
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.cardEditor .texteditor-container {
  background-color: transparent !important;
  border: none !important;
}

.cardEditor .texteditor-container .DraftEditor-root {
  background-color: transparent !important;
}

.cardEditor.longer {
  cursor: pointer;
}
.parentFooter {
  background-color: rgba(0, 0, 0, 0.75);
}

.business-input {
  border: none !important;
  border-bottom: 1px solid #707070 !important;
  border-radius: 0 !important;
}

.business-input:focus {
  border-color: #B5E413 !important;
  box-shadow: none !important;
}

.logo-group {
  margin: 1em 0 1em 0;
}

.logo-container {
  height: 80px;
  width: 80px;
}

.dashboard-row {
  padding: 0 5em 0 5em;
  display: flex;
  justify-content: center;
  margin-top: 3em;
}

.dashboard-box-background {
  /*background-image: url('../../../../assets/background.jpg');*/
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
}

.dashboard-box-content {
  padding: 2em 1em 2em 1em;
  background-color: #ffffff;
}

.dashboard-inner-row {
  justify-content: center;
}

.dashboard-small-box-background {
  height: 200px;
  background-color: #707070;
  box-shadow: 0 -2px 6px #d2d2d2;
}

.comparisonTopic {
  color: #FFF;
  font-size: 1.2em;
  padding-left: 15px;
  padding-top: 15px;
  font-weight: bold;
}

.report-icon-box {
  position: relative;
  text-align: center;
  box-shadow: 0 2px 8px #CCC;
  border-radius: 0.5em;
  padding-top: 1em;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  cursor: pointer;
}

.dashboard-small-box-background-third {
  height: 200px;
  background-color: #2E617C;
  box-shadow: 0 -2px 6px #d2d2d2;
  display: flex;
  justify-content: center;
  font-size: 7em;
  color: #fff;
  font-weight: bold;
}

.dashboard-small-box {
  padding: 1em 10px 1em 10px;
  box-shadow: 0 2px 6px #d2d2d2;
  background-color: #ffffff;
  height: 175px;
}

.big-text-ball {
  font-size: 3em !important;
  padding-top: 15px !important;
}

@media (max-width: 1200px) {
  .dashboard-small-container {
    margin-top: 2em;
  }
}

