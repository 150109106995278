.topicText {
    text-align: center;
    font-weight:700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* .plan-main-row [class*='col-'] {
    padding-left:3em; 
} */

.custom-switch{
  float: right;
}

.plan-list-item{
  background-color: #fff;
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-top: 1px solid #cccccc; 
  border-right: 1px solid #cccccc;
  border-radius: 10px;
  margin-right: 0.75em;
  /* margin-bottom: 0.1em; */
  cursor: pointer;
  /* box-shadow: 1px 2px 3px rgb(130, 130, 130); */
}

.plan-list-item:hover{
  border-color: #c7c7c7;
}

#exampleCustomSwitch{
  background-color: var(--highlight-color) !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 2.5em;
  height: 1.5em;
}

#switch-input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-in-modal{
  margin-right: 0em;
  margin-left:auto;
}

.plan-item-private{
  margin-bottom: 1em;
  padding: 0.5em;
  background-color: #cecece30;
}

.hide-editing-sidePanel{
  display: none;
}

.plan-sidePanel-toggler{
  position:relative;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 2em;
  top: 0em;
  z-index: 15;
  margin-left: -2.1em;
  padding: 0em 0.5em;
  font-size: 1.2rem;
  background-color: var(--highlight-color);
  height: 2.15em;
  border-radius: 0 0 10px 0;
  cursor: pointer;
  box-shadow: 1px 1px 6px #ccc;
}

.plan-sidePanel-toggler:hover{
  opacity:0.8;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1em;
  width: 1em;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  box-shadow: 1px 1px 2px 0 #79797999;
}

.slider.round {
  border-radius: 34px;
  box-shadow: inset 1px 1px 2px 0 #79797999;
}

.slider.round:before {
  border-radius: 50%;
}

#switch-input:checked + .slider {
  background-color: var(--highlight-color);
}


#switch-input:checked + .slider:before {
  -webkit-transform: translateX(1em);
  -ms-transform: translateX(1em);
  transform: translateX(1em);
}

.switch{
  border-radius: 15px;
}

.selected-email{
  font-weight: bold !important;
  background-color: var(--highlight-color);
  color: #FFF !important;
}

.selected-email:hover{
  font-weight: bold !important;
  background-color: var(--highlight-color);
  color: #FFF !important;
  opacity:0.8;
}

.plan-main-body{
    padding-top: 2.5em;
    padding-bottom: 3em;
    padding-left: 2em;
    padding-right: 1em;
  }

.plan-sub-row [class*='col-'] {
    padding-left:20px;
}

.dragable-window {
    margin-top: 20px;
}

.modified-data {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.modified-data.published {
    align-items: flex-start;
}

.modified-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
}

.modified-container.private {
    display: flex;
    justify-content: space-between;
}

.plan-input {
    border: 0 !important;
    cursor: text;
}

.custom-block-editor {
    min-height: 2em;
}

.private-summary-item-text {
    color: #5e6c84;
    text-decoration: line-through;
}

.delete:hover {
    background-color: red;
    color: #FFF;
    font-weight: 700;
}

.scroll-to-this {
    height: 25px;
    background:transparent;
}

.small-score-ball-plan {
    border: 5px solid #ffffff;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    background-color: #BDE533;
    text-align: center;
    box-shadow: 0 0 0 2pt #BDE533;
    color: #ffffff;
    font-size: 1.5em;
}

.plan-subtitle{
  min-width: 5em;
}

.plan-input.title {
    text-align: center;
    font-size: 1.75rem;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subTitle {
    font-size: 1.5rem;
}

.template-item.title {
    font-size: 1.5rem;
    font-weight:700;
}

.plan-main-row.draggableItems {
    overflow-y: auto;
    overflow-x: hidden;
    height: inherit;
}

.plan-button {
    background-color: #FFF;
    border: 1px solid black;
}
.copy-link{
  width:unset;
  float:right;
}

.summary-row {
    display: flex;
    align-items: center;
}

.summary-index {
    font-size: 1.5em;
    margin-top: 10px;
    padding-right: 8px;
}

.texteditor-container.readonly.plan-input {
    margin-top: 0;
}

.plan-task-icon:hover {
    background-color: #B5E413;
    border: 1px solid #B5E413 !important;
    color: #FFF;
}

.small-input-bar {
    border: solid #999 ;
    border-width: 0px 0px 1px 0px;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
}
  
.plan-input:focus {
-webkit-box-shadow: none;
box-shadow: none;
}

.task-risk-row {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    cursor: pointer;
}

.task-risk-row:hover, .task-risk-row:hover .plan-task-icon {
    background-color: #B5E413 !important;
    color:#FFF !important;
    border-color: #FFF !important;
    font-weight:700;
}

.copy-modal-whitebox {
    background-color: #fff;
    border-radius: 13px;
    padding: 2rem 0rem;
    min-height: 75%;
}

.topic-row {
    display: flex;
    padding-left: 20px;
}

.everBetter {
    align-self: flex-start;
    padding-left: 2.2em;
}

/*
    summary
*/

.summary-item {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  
.summary-topic {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
  
.plan-list-name{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
  .summary-topic:after {
    content: " ....................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................."
  }
  
  .summary-list-index {
    flex-shrink: 0;
  }

  .summary-item:hover {
    background-color:#B5E413;
    color:#FFF;
    font-weight:700;
    border-radius: 5px;
    margin-left:-0.25em;
    padding-left: 0.25em;
    margin-right:-0.25em;
    padding-right: 0.25em;
  }


  /*
    plan search
  */
  .plan-search-result {
    margin: 1em;
    padding: 2em;
    border-radius: 5px;
    box-shadow: 0 0 5px #CCC;
    cursor: pointer;
  }

  .plan-search-result:hover {
    box-shadow: 0 0 10px #CCC;
  }

  .plan-search-result-content {
    margin: 1em 0;
    cursor: inherit;
  }

  .plan-type-button {
    padding: 1em;
    margin-right: 0.5em;
    background-color: #fff;
    color: #404040;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
  }

  .add-table-row-button{
    position: relative;
    top: -1em;
    /* left: -0.5em; */
    font-size: 0.9rem !important;
    z-index: 10;
  }

  .deal-info-row{
    margin-top: 0.5em;
  }

  .cover-heading{
    min-height: auto !important;
    border-radius: 5px !important;
  }
  .cover-text{
    border-radius: 5px !important;
    max-height: 30em;
  }

  .cover-page-side-panel{
    border-right: 2px solid #6ba53f;
  }

  .cover-side-panel-title{
    margin-top: 0em;
    font-weight: bold;
  }
  .cover-side-panel-header{
    margin-bottom: 1em !important;
    border-bottom: 2px solid #6ba53f;
  }

  .paragraph-template-item{
    /* padding:0 0.25em; */
    border-radius: 5px;
    cursor: pointer;
    border-bottom: 1px solid #6ba53f;
  }

  .paragraph-template-item:hover{
    color: #fff;
    background-color: #6ba53f;
  }


  .plan-type-selected {
    color: #fff;
    font-weight: 600;
    background-color: #6ba53f;
  }

  .plan-attachment-row{
    display: flex;
    flex-direction: row;
    align-content: center;
  }
  
  .plan-attachment:hover{
    cursor: pointer;
    opacity: 0.7;
  }
  .plan-link-container:hover{
    color:rgb(0, 0, 167);
    text-decoration: underline;
    cursor: pointer;
    opacity: 0.7;
  }
  .copy-plan-link-button:hover{
    cursor: pointer;
    opacity: 0.7;
  }
  
  .copy-plan-link-button{
    border-radius: 0 10px 10px 0;
    border: none;
    color: #fff;
    background-color: var(--highlight-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  } 
  
  .plan-link-container{
    border-radius: 5px 0px 0px 5px;
    border: 2px solid var(--highlight-color);
    background-color: #FFF !important;
    cursor: pointer !important;
    width: 20vw !important;
  }

  .remove-planAttachment{
    margin-top: -0.25em;
    margin-left: 1em;
    font-size: 0.7rem;
  }

  .remove-planAttachment:hover{
    color:red;
    opacity: 0.7;
    cursor: pointer;
  }

  .check-item-title{
    width: 90%;
  }

  .check-item-row{
    padding-top: 0.25em;
    display:flex;
    margin-left: -1em;
    border-radius: 5px !important;
    align-items: center;
  }

  .check-item-row:hover{
    background-color: #eeeeee;
  }

  .add-new-checkItem-row{
    display: flex;
  }

  .check-item-box{
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
    /* margin-top: 0.2em; */
    /* align-self: ; */
  }
  .check-item-box:focus{
    -webkit-appearance:checkbox !important;
  }

  .delete-hover:hover{
    color: red;
  }

  div[class*="kn-item__with_grouping"] {
    /* border-bottom: 1px solid #acd811; */
    border-top: 2px solid #6ba53f;
    font-weight: bold;
  }

  .product-content-block{
    align-items: center;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }

  .product-headers-row{
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 0.5em;
  }

  .product-summary-row{
    margin-top: 0.5em;
    padding-top: 0.5em;
    border-top: 1px solid #dee2e6;
  }

  .scroll-to-top-button{
    display:none;
    font-size: 2rem;
    position:fixed;
    bottom: 1em;
    right: 1em;
    color: #fff;
    background-color: var(--form-color);
    cursor: pointer;
    z-index: 11;
    border: none;
    border-radius: 100%;
    padding: 0 0.3em;
    box-shadow: 4px 3px 4px 0px #ccc;
  }
  .scroll-to-top-button:hover{
    opacity: 0.8;
  }

  .scroll-to-top-edit{
    bottom: 0.75em !important;
    right: 1.5em !important;
  }

  .dropdown-plan-settings{
    position:fixed;
    top:2.9em;
    right: 0.8em;
    z-index: 99;
    font-size: 1.2rem;
    height: 2.15em;
    text-align: center;
  }
  
  .dropdown-plan-settings-preview{
    position:absolute;
    right: 0.8em;
    top: 2.9em;
    z-index: 99;
    font-size: 1.2rem;
    height: 2em;
    text-align: center;
  }

  .save-plan-button{
    position: fixed;
    bottom: 0.75em;
    /* left: 3em; */
    font-size: 2rem;
    /* width: inherit !important; */
    border-radius: 25px;
    padding: 0.1em 8em;
    color: #FFF;
    background-color: #6ba53f;
    text-align: center;
    cursor: pointer;
    box-shadow: 1px 1px 4px 0px #ccc;
  }

  .save-plan-button:hover{
    box-shadow: 2px 2px 4px 0px #8bac06;
  }

  .plan-option-toggler{
    border-radius: 0 10px 0 10px !important;
  }

  .center-in-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sign-row{
    margin-top: 1em;
  }

  .underLine{
    margin-bottom: -1px;
    border-bottom: 1px solid black;
  }

  .signature-container{
    margin-right: 2em;
    max-width: 20em;
  }

  .sign-plan-button{
    background-color: var(--highlight-color);
    color: #fff;
    font-weight: bold;
  }

  @font-face {
    font-family: 'brush-script-mt';
    src: url('../../fonts/BRUSHSCI.woff') format('woff');
  }
  .signed-name{
   font-family: 'brush-script-mt' , Fallback, sans-serif;
    /* font-style: italic; */
    color: rgb(29, 1, 94);
    font-size: 1.5rem;
  }

  @media print {
    @page { size:  auto; margin: 15px 0px; }
    .container-fluid{
      background-color: #FFF;
      padding-bottom: 0 !important;
    }
    .base-container{
     margin-left:0 !important;
     background-color: #FFF;
    }
    body, .App {
      background-color: #FFF !important;
      background-image: none;
      color-adjust: exact;
      -webkit-print-color-adjust: exact;
     overflow: visible;
    }
    .tabContent, .sidebar, .dropdown, .tag-div, .topic-col, .modified-container, .breadcrumbs, .published-row, .everBetter {
      display: none !important;
    }
    .plan-main-body{
      padding-bottom:0 !important;
    }
    .whitebox-plan{
      margin: 0 !important;
      padding:0 !important;
    }
    .ai-toggler-bottom{
      visibility: hidden !important;
    }
    span, h3{
      page-break-inside: avoid;
    }
    
  }    

  @media only screen and (max-width: 1024px) {
    .topicText{
      white-space:normal;
    }
  }