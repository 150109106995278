.ai-toggler-top{
    position: fixed;
    bottom: 464px;
    font-size: 1.5rem;
    color: #fff;
    padding:0em 0.25em;
    border-radius: 10px 0px 0px 0px;
    cursor: pointer;
    
}

.ai-toggler-bottom{
    position: fixed;
    bottom: 0em;
    font-size: 2rem;
    z-index: 999;
    color: #fff;
    padding:0.15em 0.25em;
    border-radius: 25px 25px 0px 0px;
    cursor: pointer;
}

.ai-toggler-bottom:hover{
    box-shadow: 2px 3px #ccc;
}

.ai-toggler-left{
    margin-left: -0.38em;
}

.ai-toggler-right{
    right: 15.15em;
}
.ai-toggler-bottom-left{
    left: 3.5em;
}

.ai-toggler-bottom-right{
    right: 0em;
}
.ai-textarea{
    resize:none;
    width:100%;
    margin-right: 0em;
    border: 1px solid #ccc;
    overflow:hidden;
    white-space: pre-wrap;
    margin-left:0.5em;
}

.ai-header{
    padding: 0.25em;
    text-align: center;
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    background-color: #fff;
    cursor: default;
    height:2em;
} 

.close-ai-frame{
    float:right;
    margin-right: 0.5em;
    font-weight: bold;
    font-size: 1.2rem;
    margin-top:-0.2em;
}

.ai-frame{
    border: 1px solid #adadad;
    background-color: #ffffff;
    position: fixed;
    bottom: 0em;
    z-index: 999;
    padding:0.15em 0.25em;
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
    height: 500px;
    width: 400px;
    display: grid;
}

.ai-frame-left{
    left: 7em;
}

.ai-frame-right{
    right:0em;
}

.ai-frame-body{
    padding: 0.25em;
    /* height: 430px; */
    display:grid;
}

.ai-footer{
    bottom: 1px;
    width:100%; 
    
}

.grow-wrap {
    display: grid;
    position: absolute;
    bottom: 1px;
    width: 400px;
  }
.grow-wrap::after {
    content: attr(data-replicated-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
}
.grow-wrap > textarea {
    resize: none;
    overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
    max-height: 200px;
    padding: 0 0.5rem;
    font: inherit;
    grid-area: 1/1/4/5;
    overflow-y: scroll;
}
  
  

.move-ai-right{
    position:absolute;
    bottom: 15em;
    left: 24.85em;
    background-color: #ebebeb;
    border: 1px solid #adadad;
    border-radius: 0 5px 5px 0;
    border-left: none;
    padding: 0.25em;
    opacity:0.5;
}
.move-ai-left{
    position:absolute;
    bottom: 15em;
    left: -1.20em;
    background-color: #ebebeb;
    border: 1px solid #adadad;
    border-radius: 5px 0 0 5px;
    border-right: none;
    background-color: #ebebeb;
    padding: 0.25em;
    opacity:0.5;
}

.move-ai-right:hover{
    opacity: 1;
}
.move-ai-left:hover{
    opacity: 1;
}

.ai-response-nibblet{
    width:1.5em;
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-bottom: 8px solid #ebebeb;
    align-self: flex-end;
    margin-left: -1.1em;
    margin-bottom: -0.3em;
}

.user-nibblet{
    width:1.5em;
    width: 0; 
    height: 0; 
    border-right: 8px solid transparent;
    border-bottom: 8px solid #6ba53f;
    align-self: flex-end;
    margin-left: 0.25em;
    margin-right: -1em;
    margin-bottom: -0.25em;
}

.ai-message{
    border: 1px solid #ebebeb;
    padding: 0.15em;
    max-width: fit-content;
    padding:0.25em 0.5em;
    width:auto;
    display: flex;
    margin-top: 0.25em;
}

.ai-robot-image{
    margin-right: 0.5em;
    margin-left: 0.5em;
}

.ai-response{
    border-radius: 10px 10px 10px 0;
    background-color: #ebebeb;
    margin-right: 5em;
    margin-left: 0.5em;
}
    
.user-message{
    margin-left: auto;
    max-width: 280px;
    min-width: 10px;
    width: fit-content;
    border-radius: 10px 10px 0 10px;
    background-color: #6ba53f;
    margin-right: 0.75em;
    color: #fff;
}

.ai-loader, .ai-loader:before, .ai-loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
}

.ai-loader {
    color: #404040;
    font-size: 3px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
    z-index: inherit;
    margin-bottom: 4em;
    margin-left: 5em;
}

.ai-loader:before,
.ai-loader:after {
    content: '';
    position: absolute;
    top: 0;
}
.ai-loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
}
.ai-loader:after {
    left: 3.5em;
}

@keyframes bblFadInOut {
    0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
    40% { box-shadow: 0 2.5em 0 0 }
}
      
.ai-send-button{
    border: 1px solid #ccc;
    border-radius: 0 10px 10px 0;
    padding:0.5rem 0.25em ;
    grid-area: 1/6/2/6;
    margin-left: 0.38em;
}

.plain-button{
    background-color: #fff;
    color: rgb(47, 47, 47);
}