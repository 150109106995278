.copied-container {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    display: flex;
    flex-direction: column;
    padding-left: 1em;
    padding-right: 1em;
}

.title-col.copied-container-block {
    margin-bottom: 8px;
    padding-right: 5em;
}

.iFrame-list-container {
    display: flex;
    flex-wrap: wrap;
}

.single-iframe-container {
    min-width: 20em;
    max-width: 20em;
}

.iFrame-item-block-div {
    position: relative;
    z-index: 100;
    opacity: 0.6;
    pointer-events: none;
}

.delete-button-div {
    display: flex;
    justify-content: center;
    padding-top: 1.5em;
}

.iFrame-load-div {
    height: 350px;
    width: 100%;
    background: rgb(200, 200, 200) none repeat scroll 0% 0%;
    display: flex;
    justify-content: center;
    align-content: center;
}

.iFrame-load-button {
    align-self: center;
}