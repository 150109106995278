.progress-bar-trello {
    background: rgba(9,30,66,.08);
    border-radius: 4px;
    clear: both;
    height: 8px;
    margin: 0 0 0 40px;
    overflow: hidden;
    position: relative;
}

.progress-bar-progress {
    background: #5ba4cf;
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    transition: width .14s ease-in,background .14s ease-in;
}

.percent {
    color: #5e6c84;
    font-size: 11px;
    line-height: 10px;
    position: absolute;
    left: 0;
    top: -1px;
    text-align: center;
    width: 22px;
}

.checklist-progress {
    margin-bottom: 6px;
    position: relative;
}

.checklist-items-list {
    min-height: 8px;
}
.checklist-item {
    clear: both;
    padding-left: 40px;
    position: relative;
    border-radius: 3px;
    transform-origin: left bottom;
    transition-property: transform,opacity,height,padding,margin;
    transition-duration: .14s;
    transition-timing-function: ease-in;
}

.checklist-item-checkbox {
    border-radius: 2px;
    box-sizing: border-box;
    line-height: 18px;
    overflow: hidden;
    text-indent: 100%;
    height: 18px;
    width: 18px;
    white-space: nowrap;
    background-color: #fafbfc;
    box-shadow: inset 0 0 0 2px #dfe1e6;
    position: absolute;
    left: 0;
    margin: 6px;
    text-align: center;
    top: 2px;
}

.checklist-item-details {
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.checklist-item-details .checklist-item-row {
    display: flex;
    flex-direction: row;
}

.checklist-item-fade-out .checklist-item-checkbox, .checklist-item-state-complete .checklist-item-checkbox {
    border-radius: 2px;
    box-sizing: border-box;
    line-height: 18px;
    overflow: hidden;
    text-indent: 100%;
    height: 18px;
    width: 18px;
    white-space: nowrap;
    background-color: #fafbfc;
    box-shadow: inset 0 0 0 2px #dfe1e6;
    background-color: #0079bf;
    box-shadow: inset 0 0 0 2px #0079bf;
    text-indent: 0;
}

.checklist-item-details .checklist-item-row .checklist-item-text-and-controls {
    padding: 6px 0;
    width: 100%;
    display: inline-flex;
}

.checklist-item-details-text {
    min-height: 20px;
    margin-bottom: 0;
    align-self: center;
    flex: 1;
}

.progress-bar-current-complete {
    background: #61bd4f;
}

.fa-check-square.trello {
    color: #404040;
}

.checkItem-main-row {
    display: flex;
    cursor: pointer;
}

.checkItem-main-row:hover {
    opacity: 0.6;
}

.checklist-item-fade-out .checklist-item-details-text, .checklist-item-state-complete .checklist-item-row .checklist-item-details-text {
    color: #5e6c84;
    text-decoration: line-through;
}