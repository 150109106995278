/* SURVEY LIST */

.survey-list-content {
  margin-top: .5rem;
}

.tasksText {
  overflow: hidden;

  box-sizing: border-box;
  max-width: 100%;
  height: 1.5em;
  line-height: 1.5em;
  font-size: large;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card-overflower-short {
  display: block;
  overflow: hidden;
  flex-grow: 1;
  text-overflow: ellipsis;
  max-width: 14em;
  white-space: nowrap;
}

.card-business-overflower-short {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 9em;
  
}

@supports (flex-wrap: wrap) {
  .overflower {
    display: inline-flex;
    flex-wrap: wrap;
  }

  .overflower-short {
    display: block;
    overflow: hidden;
    flex-grow: 1;
    text-overflow: ellipsis;
    max-width: 9em;
  }
}

/* SURVEY LIST END */
