.comparison-header {
    width: 100%;
    align-items: flex-start;
    display: flex;
}

.toggleHeader {
    cursor: pointer;
    padding: 1rem 0;
}

.dashboard-medium-box-collapsed {
    border-radius: 5px;
    background-color: #fff;
    margin-top: 1rem;
    box-shadow: 0 3px 6px #CCC;
    padding: 1rem 0;
}

.box-header-collapsed {
    padding: 0 0 1rem 1rem;
}

.multi-selector svg {
    color: #B5E413 !important;
}

.multi-selector .kn-search__icon___1frx4 svg {
    color: #666666 !important;
}