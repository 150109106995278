.texteditor-container {
  border: 1px solid #ddd;
  padding: 0em;
  overflow: auto;
}

.addon-toggle-button{
  box-sizing: border-box;
  background: var(--highlight-color);
  border: none;
  color: #fff;
  margin: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  height: 2.1em;
  width: 2.1em;
  font-size: 1rem;
  line-height: 0.7em;
  position: absolute;
  top: -2rem;
  right: -2em;
  box-shadow: 0px 1px 3px 0px rgb(169, 169, 169);
  overflow: hidden;
}

.texteditor-container.readonly {
  border: none;
  margin-top: 1em;
  padding: 0;
}

.texteditor-container.answer-analytic-text {
  margin-top: 2em;
}

.DraftEditor-root {
  height: 100%;
}

.DraftEditor-editorContainer {
  height: 100%;
  overflow: auto;
}

.DraftEditor-editorContainer .public-DraftEditor-content {
  height: 100%;
}

.DraftEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.DraftEditor-editor .public-DraftEditorPlaceholder-root,
.DraftEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.DraftEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.DraftEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.DraftEditor-editor .DraftEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.DraftEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.DraftEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.DraftEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.DraftEditor-activeButton {
  color: #5890ff;
}

.public-DraftEditorPlaceholder-root {
  margin-bottom: -25px;
  color: #9e9e9e;
}

.DraftEditor-alignLeft {
  text-align: left;
}

.DraftEditor-alignRight {
  text-align: right;
}

.DraftEditor-alignCenter {
  text-align: center;
}

.headlineButtonWrapper {
  display: inline-block;
}

.headlineButton {
  background-color: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: auto;
}

.headlineButton:hover,
.headlineButton:focus {
  background: #f3f3f3;
}

.draft-js-image {
  margin: .2rem;
  border-radius: 2px;
}

.draft-js-image:hover {
  box-shadow: 0 1px 5px #5890ff;
}

.mention {
  color: #677584;
  background: #edf5fd;
}

.mention-suggestions {
  border: 1px solid #eee;
  margin-top: 0.4em;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 1);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  overflow-y: scroll;
  height: 300px;
  /* display: flex;
  flex-direction: column; */
  /* box-sizing: border-box; */
  transform: scale(0);
}

.mention-suggestions-entry {
  padding: 7px 10px 3px 10px;
  transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
}

.mention-suggestions-entry-focused {
  padding: 7px 10px 3px 10px;
  transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
  background-color: #e6f3ff;
}

.mention-suggestions-entry-text {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 0.9em;
  margin-bottom: 0.2em;
}

