.email-check-box{
    float: right;
    margin-top: -1.85em;
    margin-right: 0.75em;
}

.email-input{
    margin-top: 0.25em;
}

.email-topic{
    margin-top: 0.25em;
}

.email-text{
    margin-top: 0.25em;
    min-height: 15em;
}

.content-label{
    margin-top: 1em;
}

.form-control:focus{
    box-shadow: unset !important;
}

.send-email-button{
    margin-top: 1.5em;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em 0.5em;
}

.email-sender-container a:hover{
    color: #404040 !important;
}

.email-sender-container label{
    font-weight: bold;
}

.email-list-row{
    display: flex;

}
