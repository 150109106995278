.annotation-container{
    margin-left: 1em;
    background-color: #fff;
    box-shadow: 2px 3px 8px 0 #cccccc;
    border-radius: 5px;
}

/* .editor-container{
    margin-left: 1em;
    width: 17em;
    min-width:17em;
    height:8em;
    border-radius: 5px;
    padding: 0.25em;
    box-shadow: 2px 3px 8px 0 #cccccc;
} */

.comment-section{
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.color-picker {
   display: flex;
   flex-direction: row;
}

.color-picker input {
    visibility: hidden;
    width: 0;
    margin: 0;
}

.color-dot{  
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
    box-shadow: 2px 2px 5px 0 #cccccc;
}

.color-dot:hover{ 
    opacity: 0.8;
}

.active-color{
    display:flex;
    justify-content: center;
    align-items: center;
}

.annotation-textarea{
    width: 100%;
    resize: none;
    font-size: 1rem;
    border: none;
    font-weight: 300;
}

.annotation-textarea:focus{
    outline: 0;
}

.add-annotation-button{
   margin-right: 0.25em;
   float: right;
   color: #fff;
   background-color: var(--highlight-color);
   font-weight: bold;
   text-align: center;
   border-radius: 10px;
   padding: 0.25em 1em 0.25em 1em;
}

.annotation{
    display:flex;
    flex-direction: row;
    width: 13em;
    padding: 0.25em 0em 0.25em 0.75em;
    height:fit-content;
    border-radius: 5px 5px 5px 5px;
    margin: 2px;
}

.delete-annotation{
    position:relative;
    top:0.25em;
    right: 0em;
    font-size: 0.8rem;
    cursor: pointer;
}

.annotation-col{
    display: flex;
    flex-direction: row;
    width: 11em;
    max-width: 11em;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
}

.annotation-tool-icon:hover{
    opacity:0.8;
}

.annotation-timestamp{
    font-size: 0.8rem;
    opacity:0.6;
    font-weight: bold;
}