.card-elements {
    background-color: inherit;
    min-width: 100%;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
}

.horizontal-container {
    display: flex;
    /* margin-bottom: -0.75em; */
}

.horizontal-container ul {
    background-color: inherit;
    min-width: 100%;
    scrollbar-width: none;
    scroll-behavior: smooth;
    overflow-x: scroll;
    /* overflow-y: hidden; */
    /* border-bottom: 2px solid #ddd; */
    /* border-radius: 10px; */

}

/* Chrome/Safari/Webkit */
.horizontal-container ul::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
}

.horizontal-container .caret-left, .horizontal-container .caret-right {
    position: absolute;
    align-self: center;
    font-size: 2.5em;
    opacity: 0.5;
    cursor: pointer;
    z-index: 5;
}

.horizontal-container .caret-left:hover, .horizontal-container .caret-right:hover {
    opacity: 1;
}

.caret-left {
    margin-left: 0.35em;
}

.caret-right {
    right: 0;
}

.card {
    width: 100%;
    cursor: pointer;
    border-radius: 10px;
    /* border: 0; */
    
}

.card:hover {
    cursor: pointer;
    scale: 1.02;
    border-bottom:2px solid #ccc;
    margin-top: -2px;
    border-left: 1px solid #ccc;
}

.horizontal-main-container {
    /* border-radius: 5px; */
    /* background-color: #FAFAFA; */
    margin-top: 1rem;
    /* box-shadow: 3px 3px 3px #ddd; */
    /* overflow: hidden; */
    /* border-radius: 0px 0px 10px 0px; */
    /* border-bottom: 2px solid #ddd;
    border-right: 2px solid #ddd; */
}
.vertical-main-container {
    border-radius: 5px;
    /* background-color: #FAFAFA; */
    margin-top: 1rem;
    /* box-shadow: 0 3px 6px #CCC; */
    padding-bottom: 1em;
    /* border-bottom: 2px solid #ddd;
    border-right: 2px solid #ddd; */
}
.view-type-container{
    padding: 0 0.5em;
    font-size: 0.9rem;
}

.active-type{
    background-color: var(--highlight-color);
    color: var(--highlight-font-color);
}

.title-bar {
    display: flex;
    align-items: center;
}

.card-element {
    width: 100%;
    height: 100%;
    text-align: left;
}

.dashboard-list-container{
    margin-top: 0.5em;
}

.card--content {
    min-width: 15em;
    max-width: 15em;
    margin-right: 0.5rem;
    max-height: 9em;
    min-height: 9em;
    border-radius: 10px;
}

.card-title{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10em;
    font-size: 1rem;
}
.contact-card-body{
    flex: 1 1 auto;
    padding: 0 1em 0 1.25em;
}
.contact-tags{
    margin-top: -1em;
}

.card-title-client{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 7.5em;
}

.card-text{
    font-size: 0.7rem;
}

.card-extra{
    display: flex;
    flex-direction: row;
}

.tag-row{
    flex-wrap: wrap;
}

.graphic-circle-container {
    display: flex;
    justify-content: center;
}

.horizontal-container ul.card-elements {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
    /* max-height: 200px; */
}

.list-placeholder-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.task-card-title {
    display: inline-block;
    overflow: hidden;
    box-sizing: border-box;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.horizontal-container ul li .card--content {
    display: flex;
    color: #404040;
    text-align: left;
    text-decoration: none;
    background-color: #FFF;
    float: left;
    /* box-shadow: 0 3px 6px #CCC; */
}

.title-header {
    display: flex;
    justify-content: space-between;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: #FFF;
    border-bottom: 0;
}

.creation-card{
    min-width: 10em;
    display:flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    /* align-items: center; */
    min-height: 4em;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    margin: 0.5em;
    border-radius: 30px;
    box-shadow: 2px 3px 4px #c8c8c8;
    padding: 2px;
    background-color: #ebebeb;
    /* border: 2px solid #a1a1a1; */
}
.creation-card:hover{
    /* opacity:0.8; */
    box-shadow: 2px 3px 6px rgb(147, 147, 147);
}

.creation-card-inner{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-color: #fff; */
    width: 100%;
    height: 100%;
    color: rgb(36, 36, 36);
    border-radius: 30px;
}

.custom-footer {
    display: flex;
    justify-content: space-between;   
    border-top: 0;
    align-items: center;
    min-height: 25%;
    max-height: 25%;
    border-radius: 0 0 10px 10px;
}

.task-footer{
    padding: 0.5em 1.25em 0.5em 1.25em;
}

.horizontal-container-settings {
    flex: 1;
    padding-right: 1rem;
    overflow: hidden;
}

.settings-icon {
    cursor: pointer;
    float: right;
    font-size: 1.5rem;
}

.label-bar {
    /* padding-left: 1rem; */
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;
}

.vertical-label-bar {
    /* padding-left: 1rem; */
    padding-top: 0.5rem;
    padding-bottom: 0.55rem;
}

.dashboard-list-row{
    background-color: #fff;
    padding: 0.5em 0.5em 0.5em 1em;
}

.contact-smaller-text{
    font-size: 0.7rem;
}

.contacts-footer{
    background-color: var(--contact-color-light);
}

.contacts-title{
    font-weight: bold;
}
.contacts-footer{
    display: flex;
}

.contacts-divide{
    display: flex;
    flex-direction: row;
    height:75%;
    
}

.remove-contact-icon{
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0.25em;
    margin-right: 0.25em;
}
.list-container{
    max-height:300px;
}

.fa-times:hover{
    color:red;
}