.typeSelectors {
    display: inline-block;
}

.senderOptions .col {
    padding: 1rem;
}

.senderSidePanel .row {
    padding: 2rem;
}

.senderSidePanel {
    height: 100%;
    background-color: #404040;
}

.senderContainer {
    background-color: #FFF;
}

.senderTypes .btn {
    width: 15em;
    background-color: #FFF;
    color: #404040;
    border: 1px solid grey;
    margin: 1rem;
}

.surveyButton .btn {
    width: 100%;
    background-color: #FFF;
    color: #404040;
    border: 1px solid grey;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    word-wrap: anywhere;
}

.reminderExplanation {
    height: 200px;
}

.senderContainer {
    max-height: 600px;
    min-height: 600px;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    overflow-x: hidden;
}

.senderContainer.magic {
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.4s linear;
    -moz-transition: all 0.4s linear;
    transition: all 0.4s linear;
}

.jss19 {
    color: #B5E413 !important;
}

.emailAdder {
    width: 100% !important;
}

.emailAddButton {
    width: 20%;
    font-size: 2rem;
    color: #B5E413;
}

.dropdown-item a {
    color: #212529;
}

.emailAddButton-disabled {
    width: 20%;
    font-size: 2rem;
    color: #ED5652;
    pointer-events: none;
}

.emailAddButton:hover {
    text-decoration: none;
}

.test {
    height: 45px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 0 12px 0 0;
    border-bottom: 1px solid #dddddd;
}

.testInput {
    height: 45px;
    padding: 0 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex: 1 1;
    flex: 1 1;
    outline: none;
    border: 0;
    font-size: 14px;
    background: transparent;
}

.chooseType {
    padding-bottom: 1rem;
}

.companySelectors {
    padding-top: 1rem;
}

.senderSteps {
    font-size: 1rem;
    font-weight: 700;
    /* padding-top: 1rem; */
    padding-bottom: .25rem;
}

.colored-survey-border{
    border-left: 2px solid var(--survey-color);
    height: 100%;
    margin-top: 1em;
    margin-bottom: 2em;
}

.companySelector {
    /* padding-top: 1rem; */
    min-width: 15em;
    opacity: 0;
    visibility: none;
}

.selector {
    display: none;
}

.selector-visible {
    display: block;
    margin-top: .5rem;
}

.companySelector.magic {
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.dropdownMenuCard {
    background-color: inherit !important;
    border: none !important;
    color: #404040 !important;
}

.dropdownMenuCard:focus {
    box-shadow: none !important;
}

.sender-text-area {
    height: 3.5rem
}

.background-image {
    height: auto;
    width: 100%;
    cursor: pointer;
}

.background-image:hover {
    box-shadow: 3px 5px 9px #d2d2d2;
}

.background-edit {
    width: 100%;
    padding-bottom: 66.55%;
    background-size: contain;
    background-repeat: no-repeat;
    transform: scale(1.1);
}

.chrome-picker {
    box-shadow: none !important;
}

.active-button {
    background-color: var(--highlight-color);
}