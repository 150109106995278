body {
  margin: 0;
  padding: 0 !important;
  font-family: 'Raleway' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #404040 !important;
   background-color: #EBEBEB !important; 
  /* padding-top:60px; */
}

.App {
  overflow: auto;
}

