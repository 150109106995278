.tree-head {
  color: #000000 !important;
  cursor: pointer; 
  font-weight: 600;
}

.tree-node {
  color: #404040;
}

.tree-node-active {
  background-color: #DEDEDE !important;
}

.tree-node-wrap {
  background-color: #FAFAFA;
  padding-bottom: 0.1em;
  padding-top: 0.1em;
}

.tree-node-wrap:hover {
  background-color: #DEDEDE;
}

.tree-node-wrap a, .tree-node-wrap a:hover {
  text-decoration: none;
  color: #404040;
}

.tree-node-wrap, .tree-header, .reportCol {
  padding-left: 0.8em !important;
}

@media (-moz-touch-enabled: 1), (pointer:coarse) {
  .tree-node-wrap:hover {
    background-color: inherit;
  }
}