.favourite-star{
    cursor: pointer;
    color: #FFD700;
}

.favourite-star:hover{
    opacity: 0.8;
}

.favourite-item-color-indicator{
    width: 100%;
    height: 100%;
    margin-left: -0.5em;    
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    border-radius: 0 10px 10px 0;
    border-bottom: 1px solid #c5c5c5;
}

.favourites-search-bar{
    display:flex;
    padding-bottom: 0.5em;
    margin-bottom: 1em;
    /* border-bottom: 1px solid #acd811; */
}