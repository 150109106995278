.gantt-row-odd{
    background-color: #f5f5f5;
}

.gantt-row-even{
    background-color: #fff;
}

.gantt-row-styles{
    display: flex;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 0;
    position: relative;
}

.gantt-row-styles:hover{
   background-color: #ebebeb;
}

._3zRJQ{
    fill: #404040 !important;
    font-weight: bold !important;
}

.color-square{
    height: 1.5em;
    width: 1.5em;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 0.25em;
    margin-bottom: 0.25em;
}