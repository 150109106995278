
/*
  :root - allows to creation and usage of variables in css.
  Can be used for theming the site.
*/
:root {
  --highlight-color: #6ba53f;
  --background: white;
  --highlight-font-color: #404040;
  /*!---> above styles affect every page */
  --enterprise-title-color: #404040;
  --enterprise-background-color: #fff;
  --enterprise-primary-color: #6ba53f;
  --enterprise-secondary-color: #404040;
  --project-color: #D811AC;
  --contact-color: #c9c9c9;
  --survey-color: #ffc107;
  --client-color: #27598C;
  --form-color: #27D9D4;
  --product-color: #e2cfec;
  --risk-color: #DB5E2B;
  --tools-color: #e2cfec;
  --activity-color: #441c90;
  --extra-color: #E3E3E3;
  --project-color-light: #D811AC15;
  --contact-color-light: #E3E3E3;
  --survey-color-light: #F4E5C370;
  --client-color-light: #CAD8F970;
  --form-color-light: #CCE4E870;
  --product-color-light:#e2cfec70;
  --risk-color-light: #f7ccad70;
  --tools-color-light: #e2cfec70;
  --extra-color-light:#E3E3E3;
  --background-color: #fff;
}

.form-color{
  background: var(--form-color) !important;
}

.project-color{
  background: var(--project-color) !important;
}
.contact-color{
  background: var(--contact-color) !important;
}

.client-color{
  background: var(--client-color) !important;
}

.survey-color{
  background: var(--survey-color) !important;
}
.answer-color{
  background: var(--survey-color) !important;
}
.product-color{
  background: var(--product-color);
}

.risk-color{
  background: var(--risk-color) !important;
}

.task-color{
  background: var(--risk-color) !important;
}

.tools-color{
  background: var(--tools-color) !important;
}

.contact-color{
  background: var(--contact-color) !important;
}

.form-lighter-color{
  background-color: var(--form-color-light) !important;
}
.project-lighter-color{
  background-color: var(--project-color-light)!important;
}

.client-lighter-color{
  background-color: var(--client-color-light) !important; 
}

.survey-lighter-color{
  background-color: var(--survey-color-light) !important;
}

.product-lighter-color{
  background-color: var(--product-color-light) !important;
}

.risk-lighter-color{
  background-color: var(--risk-color-light) !important;
}

.task-lighter-color{
  background-color: var(--risk-color-light) !important;
}

.tool-lighter-color{
  background-color: var(--tools-color-light) !important;
}

/* div{
  background-color: var;
} */

.border-red{
  border: 1px solid red !important;
}

.App {
  text-align: left;
  background-color: #EBEBEB;
}

p {
  margin-bottom: 0 !important;
}

html {
  overflow-y: auto !important;
}

.container-fluid {
  padding-bottom: 1em;
}

.newQuestion {
  width: 100% !important;
}

.tab {
  width: 100%;
  background-color: transparent;
  text-align:center;
}

.errorText {
  color: red;
}
.userEdit {
  margin:5%;
}

.enterprise-styled-button:hover{
  background-color: var(--highlight-color) !important;
  color: #fff !important;
  font-weight: bold !important;
}

input:not(.form-check-input):focus{
  outline:0px !important;
  -webkit-appearance:none;
  appearance: none;
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.select__control{
  outline:0px !important;
  -webkit-appearance:none;
  appearance: none;
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.followSelection {
  background-color: #FFFFFF;
  color: #000000;

  -webkit-box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);
  -moz-box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);
  box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);

  border-radius: 5px;
  border: none;
  margin: 0 1rem 0 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 12em;
}

.helper-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.helper-box-div {
  height: 450px;
  width: 450px;
  border-radius: 50%;
  background-color:#F7F7F7;
  text-align: center;
  display: flex;
  align-items: center;
}

.helper-box-content {
  width: 100%;
  padding: 0 2em 0 2em;
}

.helper-box-content h3, .helper-box-content p {
  padding-top:1em;
}

.followSelection.selected, .followSelection:hover:enabled {
  background-color: var(--highlight-color);
}

.typeSelections {
  background-color: #FFFFFF;
  color: #000000;

  -webkit-box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);
  -moz-box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);
  box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);

  border-radius: 5px;
  border: none;
  margin: 0 1rem 0 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 8rem;
}

.typeSelections.selected, .typeSelections:hover:enabled {
  background-color: var(--highlight-color);
}

.companyScoreLarge-container {
  padding: 1em;
}

.riskIconCol {
  -ms-flex: 0 0 2.666667% !important;
  flex: 0 0 2.666667% !important;
  max-width: 16.666667% !important;
}

.riskExplanationEditor .texteditor-container {
    height: 150px;
}

.reportBaseList {
  z-index: 4;
}

.infoBlock {
  color: rgb(255, 204, 0);
  z-index: 99;
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  cursor: pointer;
  border-radius: 50%;
  font-size: 1.5em;
}

.small-white-button.selected {
  background-color: var(--highlight-color);
  color: #FFF;
}

.small-white-button.lighter-text.selected {
  font-weight: 400;
}

.riskMatriceContainer {
  height: 280px;
  width: 282px;
  border: 1px solid black;
  display: flex;
  flex-flow: wrap-reverse;
}

.oddsTextContainer {
  position: absolute;
  height: 200px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  left: -1.2rem;
}

.oddsText {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  pointer-events: none;
  font-size: 1.2em;
}

.vaikuttavuusText {
  font-size: 1.2em;
  padding-top:0.3em;
  text-align: center;
  width: 282px;
}

.riskMatriceDotContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.riskMatriceCell {
  height: 70px; 
  width: 70px;
  border: 1px solid black;
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
}

.form-check-input {
  position:relative !important;
}

.mainContainer {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.trial-left-div{
  color: red;
  /* text-align: center; */
  display: flex;
  width: 100%;
  margin-bottom: -1.5em;
  justify-content: center;
  font-size: 0.9rem;
}

.logo-container {
  position: relative;
  height: 80px;
  width: 80px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.userEdit .col-sm-3 {
  margin-left:30%;
}

.userEdit .col-sm-3 .row {
  padding-top: 20px;
}

.tabEnterprise {
  width: 50%;
  background-color: #CACACA;
  text-align:center;
}

.companyCol input {
  width: 100%;
}

.tab.active {
  background-color: #F8F8F8;
}

.tabEnterprise.active {
  background-color: #F8F8F8;
}

.delete + .done {
  margin-top: 0 !important;
}

.sidepanel-container {
  /* width: 6rem; */
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.sidePanel {
  background-color: #F8F8F8;
  /* height: 100%; */
  position: absolute;
  width: 100%;
}

.sidePanel-closed {
  visibility: hidden;
}

.tietosuoja {
  overflow:auto;
  min-height: 80vh;
  max-height: 80vh;
}
.close {
  font-size: 1.3em;
}
.itemAdd {
  background-color: var(--highlight-color);
  color: #ffffff;
  height: 53px;
  width: 53px;
  line-height: 53px;
  vertical-align: middle;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.itemAdd:hover {
  opacity: 0.8;
}

.noteTd {
  word-wrap: break-word;
}
.navigation-bar {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  z-index:101;
  position: fixed !important;
  width: 100%;
  top: 0px;
  left: 0px;
}

.plan-sidePanel{
  color:#FFFFFF;
  background-color:  var(--highlight-color);
  border-radius: 0 0 0 0;
  border-bottom: 1px solid  #CCC;
  font-weight: 700 !important;
  margin-top: 0 !important;
  margin-left: 2em;
  /* padding-top: 0.5em !important;
  padding-bottom: 0.5em !important; */
}
.plan-panel{
  box-shadow: 0 3px 6px #CCC;
  border-radius: 5px 0 0 5px;
  max-width:14em;
}

.dashboard-button{
  display: flex;
  justify-content: center;
  text-align: center; 
  align-items: center;
  border: 1px solid #ddd;
  padding: 0.3em 0.75em;
  background-color: var(--background-color);
  border-radius: 10px;
  height: 100%;
  box-shadow: 1px 1px 2px 0px rgb(217 212 212);
}

.create-from-dashboard-button{
  -webkit-box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);
  -moz-box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);
  box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);

  border-radius: 10px; 
  border: none;
  color: #fff;
  background-color: var(--highlight-color);

  font-weight: bold;
  padding: 0.25em 1em 0.25em 1em;
  float: left;
  font-size: 1.25rem;
  width: 90%;
}
.create-modal .whitebox{
  height:100% !important;
}

.create-modal .whitebox .row{
  width: 100% !important;
}
.create-modal .container-fluid{
  padding:0 !important;
}
.navigation-bar .collapse .navbar-nav .nav-item {
  background-color: #FFFFFF;
}
.nav-item{
  margin: 0rem 0rem 0rem 0rem;
}

.navigation-bar .nav-item a {
  color: #777777 !important;
  font-size: 1.3em;
}

.backToKartoitus {
  background-color: #FFFFFF;
  cursor: pointer;
  position:fixed;
  width: 100%;
  z-index: 99
}

.stopComparing {
  background-color: #749DB4 !important;
  color: #fff;
  cursor: pointer;
  position:fixed;
  width: 100%;
  z-index: 99
}

.stopComparingCol {
  padding-top: 10px !important;
  text-align: center;
}

.backToCol {
  padding-left: 50px !important;
  padding-top: 10px !important;
}

.backToIcon {
  padding-right: 25px !important;
}

.navigation-bar .collapse .navbar-nav li.nav-item {
  padding-left: 25px;
  padding-right: 25px;
}

.navigation-bar .collapse .navbar-nav .nav-item .nav-link {
  font-size: 1.2em;
}
.nav-link {
  padding: .5rem 0 !important;
}
.App-link {
  color: #61dafb;
}

.header-logo {
  width: 35px;
}

.kartoitusExplanation {
  margin-bottom: 37px;
}

.kartoitusCol {
  padding-top: 20px;
  padding-left: 0px;
}

.dialogMenu {
  background-color: rgba(248, 248, 248, 1);
  left: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 100%; */
  padding-left:0 !important;
  padding-right: 0 !important;
}

.saveButtonRow {
  position: relative;
  /* margin-bottom: 30px; */
}

.dialogList {
  padding-top:60px;
  /* padding-bottom: 60px; */
}

.colorPicker {
  z-index: 9999;
  position:absolute !important;
  width: 400px !important;
}

.saveButton {
  background-color: var(--highlight-color) !important;
}

.saveButton-disabled {
  background-color: #FFFFFF !important;
  color: #404040 !important;
}

.drawn_line {
  width: 1.5px;
  background-color: black;
  margin: 0 auto;
  position:absolute;
  left: 0px;
}

.dialogData {
  background-color: #FFFFFF;
  position: absolute !important;
  right: 0px;
  /* height: calc(100% - 70px); */
  overflow-y: auto;
  overflow-x: auto;
  z-index: 100;
  border-left: 1px solid rgba(0,0,0,.125);
}

.deleteQuestionOption {
  color: red;
  font-size: 3em;
  cursor:pointer;
}

.companylistItem {
  border: 1px solid #B1B1B1;
  margin-bottom: 20px !important;
}
.dialogListOption {
  border: 0 !important;
  background-color: #F8F8F8 !important;
  cursor: pointer;
  color: #404040 !important;
  padding: 0.5rem;
}

.btn-secondary.focus, .btn-secondary:focus {
  box-shadow: none !important;
}

.senderBuilder input {
  width: 100%;
}

.senderBuilder .row {
  padding-top: 20px;
}

.senderBuilder .row .col .row {
  padding-top: 0;
}

.iconsArea > .col {
  max-width: 23%;
}

.iconArea {
  margin-bottom:20px;
  text-align: center;
  border: 1px solid #707070;
}

.iconArea.selected {
  background-color: var(--highlight-color);
  color: #FFFFFF;
  border: hidden;
}

.listIcons i {
  float:right;
}

.ribbon {
  margin: 0;
  padding: 0;
  background: rebeccapurple;
  color:white;
  padding:1em 0;
  position: absolute;
  top:0;
  right:0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
}
.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  top:0;
  margin: 0 -1px; /* tweak */
  width: 100%;
  height: 100%;
  background: rebeccapurple;
}
.ribbon:before {
  right:100%;
}

.square {
  height: 58px;
  width: 58px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px;
}

.one-line-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.points {
  margin-bottom:30px;
}

.categoryListSender button {
  border-radius:0;
  background-color: #FFFFFF;
  color: #707070;
  margin-bottom: 10px;
  height: 100%;
  width:90%;
  white-space: normal !important;
  text-align:left;
}

.buttonContainer {
  margin-bottom: 15px;
}
.toggler {
  font-size: 1.8rem;
}

.subGroup {
  float:right;
}

.rightButton {
  padding-left: 0 !important;
}

.leftButton {
  padding-right: 0 !important;
}

.leftButton.selected button{
  background-color: #707070 !important;
  color: #FFFFFF;
}

.group.selected button {
  background-color: var(--highlight-color) !important;
  color: #FFFFFF;
}

.mainButtonRow button {
  width: 100%;
}
.mainButtons {
  width: 100%;
  height:100%;
}

.nav-icon {
  display: block;
}

.rightButton button:focus {
  box-shadow: none;
}

.leftButton button:focus {
  box-shadow: none;
}
.categoryBox {
  background-color: var(--highlight-color) !important;
  text-align: center;
  width: 300px;
  min-height: 200px;
  color: #FDFDFD;
}

.groupFeedBack {
  width: 100%;
}

.groupFeedbackPoints {
  max-width: 300px !important;
}

.groupFeedbackPoints .row {
  margin-top: 20px;
}

.listItem {
  cursor:pointer;
  padding: 0 !important;
  background-color: #fff;
}

.listItem.btn-secondary {
  background-color: #fff !important;
  color: #404040 !important;
}

.groupToggler {
  background-color: transparent !important;
  color: #404040 !important;
  border: none !important;
  padding: 0 !important;
}

.listItem-active {
  background-color: #d2d2d2 !important;
}

.card {
  justify-content: center;
}
.card-new-header{
  display: flex;
  flex-direction: row;
  flex: 1 3;
  padding-left: 1.25em;
  padding-top: 0.5em; 
  max-height: 25%;
  min-height: 25%;
  width: 100%; 
  justify-content: space-between ;
}

.categoryCol {
  padding-top: 20px !important;
  padding-right: 0 !important;
  max-width: 300px !important;
  margin-right: 15px !important;
}

.categoryBoxText {
  vertical-align: middle;
  line-height: 200px;
  color: #FDFDFD;
  width: 100%;
}

.kartoitusTool {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
}
.itemContainer_Title {
  padding-top: 17px;
  padding-bottom: 17px;
}
/* 
.DateInput {
  width: 45% !important;
} */
.addCard {
  cursor:pointer;
}

.categoryTextArea {
  border: 1px solid #707070;
  width: 100%;
  min-height:2.5em;
  max-height: 11em;
  margin-bottom: 10px;
}

.questionOptionTextArea {
  height: 180px;
  width: 100%;
  overflow-y: auto;
}

.planItem {
  width: 100%;
  min-height: 40px;
}

.questionOptionSmallTextArea {
  height: 100%;
  width: 100%;
}

/*.QuestionGroupEdit {
   padding-bottom: 50px; 
}*/

.question-active .row {
  font-weight: bold;
}

.reportIcons {
  font-size: 4em;
  padding:25px;
}

.reportIconsText {
  font-size: 2em;
  padding: 25px;
}

.categoryContainer_Title {
  padding-top: 30px;
  padding-bottom: 30px;
}

.groupFeedbackItem {
  padding-top: 20px;
}

.togglerIcon {
  height: 100%;
  border-top: 1px solid #707070;
  border-right: 1px solid #707070;
  border-bottom: 1px solid #707070; 
}

:focus {
  outline: none !important;
}

.general-button {
  background-color: #fff !important;
  border: 0px !important;
  color: #777 !important;
  font-size: 1.4em !important;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,.10) !important;
}

.general-button-report {
  background-color: #fff;
  color: #777;
  box-shadow: 0 3px 5px 1px rgba(0,0,0,.10);
  border-radius: 5px;
  text-align: center;
  border: none;
  cursor: pointer;
  margin: 0 .5rem .5rem 0;
  padding: 0.5rem 1rem;
}

.general-button-report:hover {
  color: #000000;
  background-color:var(--highlight-color) !important;
  transition:0.1s ease;
  -moz-transition:0.1s ease;
  -webkit-transition:0.1s ease;
}
.general-button-report i{
  cursor: pointer;
}
.report-button-wrapper{
  margin-top: 25px;
  padding-right: 5rem !important;
}

.general-button-report-active {
  color: #000000;
  background-color: var(--highlight-color) !important;
  transition:0.3s ease;
  -moz-transition:0.3s ease;
  -webkit-transition:0.3s ease;
}

.clear-answer-session-filter-button {
  background-color: red;
  color: #fff;
  font-size: 1.4em;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,.10);
  width: 10%;
  margin-right: 5px;
  float: right;
  border-radius: 50px;
  text-align:center;
  padding-top: 5px;
  border: 0px;
  cursor: pointer;
}

.clear-answer-session-filter-button:hover {
  color: #000000 !important;
  background-color:#fff !important;
  transition:0.1s ease;
  -moz-transition:0.1s ease;
  -webkit-transition:0.1s ease;
}

.category-button:hover {
  background-color: #777 !important;
}

.tabContent .row {
  padding-top: 20px;
}

.group-column {
  padding: 5px 0 25px 0;
  border-left: 2px solid #6c757d;
}

.company-button {
  background-color: #fff;
  color: #707070;
  border-color: #ccc;
}

.user-add-button {
  background-color: var(--highlight-color) !important;
}

.dashboard-small-container {
  cursor: pointer;
}

.cursor-p {
  cursor: pointer;
}

.dashboard-small-box-background {
  display: flex;
  justify-content: center;
  font-size: 7em;
  color: #fff;
  font-weight: bold;
}

.modal-footer button.general-button-whitebackground.btn.btn-secondary {
  background-color: transparent !important;
  border: none;
  border-top: 2px solid #777777 !important;
  border-bottom: 2px solid #777777 !important;
  border-radius: 0;
  font-weight: bold;
  color: #777777 !important;
  padding: 10px 0 10px 0;
  box-shadow: none !important;
  cursor: pointer;
  width: 100% !important;
}

.refresh-button{
  width:2.5em;
  height:2.5em;
}

.modal-footer button.general-button-whitebackground.btn.btn-secondary:hover {
  opacity: 0.8;
}

.modal-content {
  border-radius: 0.4rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.freetext-answers-modal {
  max-width: 1000px !important;
}

.question-edit-navitem {
  background-color: transparent !important;
  min-width: 30%;
  margin-top: 0 !important;
  width: 50% !important;
}

a {
  cursor: pointer;
}

a:hover {
  text-decoration: none !important;
}

.question-edit-nav {
  height: 25px;
  font-size: 0.85rem;
}

.hover-opa:hover {
  opacity: 0.8;
}

.dashboard-container {
  /* height: 100%; */
  width: 100%;
}

.big-bold {
  font-size: 2.3rem;
  font-weight: bold;
  margin-bottom: 0;
}

.bold {
  font-weight: bold;
}

.big-text {
  font-size: 1.1rem;
}

.bigger-text {
  font-weight: 600;
}

.light-text {
  font-size: 0.9rem;
}

.lighter-text {
  font-size: 0.8rem;
  font-weight: 300;
}

.crumb-overflow-handler{
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15em;
  white-space: nowrap;
}

.bold {
  font-weight: bold;
}

.odd-row {
  padding: 0.5rem;
  /* padding-left: 2rem; */
  background-color: #e7e7e7;
}

.even-row {
  background-color: #F5F5F5;
  padding: 0.5rem;
  /* padding-left: 2rem; */
}

.nav-pills .nav-link{
  border: 1px solid #ebebeb;
  border-radius: 15px ;
  margin-right: 0.25em;
  box-shadow: 1px 1px 2px #ccc;
}

.nav-pills .nav-link:hover{
  background-color: #ebebeb;
}

.nav-pills .nav-link.active{
  background-color: #e7e7e7;
  color: #404040;
  border-radius: 15px !important;
  box-shadow: 1px 2px 3px rgb(130, 130, 130);
  margin-top: -2px;
  border: 1px solid #ccc;
}

.list-row{
  /* background-color: #fff; */
  border-bottom: 1px solid #EBEBEB;
  border-left: 1px solid #EBEBEB;
  border-top: 1px solid #EBEBEB; 
  border-right: 1px solid #EBEBEB;
  border-radius: 10px;
  margin-right: 0.75em;
  cursor: pointer;
}

.list-row:hover{
  border-bottom: 1px solid #999;
  border-left: 2px solid #999;
  border-top: 1px solid #999;
  border-right: 1px solid #999;
  /* margin-top: -1px; */
}

.go-back-link{
  color:#001edf !important;
  text-decoration: underline !important;
}

/* .go-back-link:hover{
  
} */

.selectable-row {
  cursor: pointer;
  color: #404040 !important;
  text-decoration: none !important;
}

.selectable-row:hover {
  /* background-color: #efefef; */
  text-decoration: none !important;
}

.side-additional-nav {
  top: 0;
  bottom: 0;
  left: 6.5rem;
  z-index: 98;
  background-color: #efefef;
  box-shadow: 0px 3px 10px #CACACA;
  position: absolute !important;
  /* height: 100%; */
  width: 20rem;
  padding: 1rem;
}

.small-white-button {
  -webkit-box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);
  -moz-box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);
  box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);

  border-radius: 5px;
  background-color: #fff;
  border: none;
  padding: 0.35rem;
  color: rgb(47, 47, 47);
  /* min-width: 6.5rem; */
}

.small-white-button:hover {
  opacity: 0.7;
}

.modify-dates-list {
  padding: 0.75em 0 !important;
}

.highlight-row {
  background-color: #dedede;
}

.highlight-row div {
  font-weight: 600;
}

.dashboard-main {
  margin: 0 !important;
  width: 100%;
  overflow-x: hidden;
}
.handle-planTags-button{
  background-color: var(--highlight-color);
}
.dashboard-big-box {
  border-radius: 10px;
  background-color: #fafafa;
  padding: 0.5em;
  margin-top: 1rem;
  box-shadow: 0 3px 6px #CCC;
  font-weight: bold;
  font-size:1.5rem;
  text-align: center;
}

.dashboard-big-box-report {
  border-radius: 5px 5px 15px 15px;
  background-color: #fff;
  padding: 1rem;
  margin-top: 1rem; 
  box-shadow: 0 3px 6px #CCC;
}

.dashboard-medium-box {
  border-radius: 10px;
  background-color: #fff;
  padding-bottom: 1rem ;
  margin-top: 1rem;
  box-shadow: 0 3px 6px #CCC;
}

.dashboard-survey-row {
  padding: .75rem 1rem .75rem 1rem;
}

.dashboard-business-row {
  padding: .5rem 1rem .5rem 1rem;
}

.dashboard-npsScore{
  border: 5px solid transparent;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  background-color: #BDE533;
  display:flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0 0 0 2pt #BDE533; */
  color: #ffffff;
  font-size: 1rem;
}

.sent-survey-row {
  cursor: pointer;
}

.sent-survey-row:hover {
  background-color: #dedede
}

.empty-dashboard-row {
  padding: 1rem 2rem;
}

.box-header {
  padding: 1em 0 1rem 1rem;
  border-bottom: 1px solid var(--highlight-color);

}

.report-filter-box {
  position: sticky;
  top: 0;
  z-index: 5;
}

.filter-nibblet {
  background-color: var(--highlight-color);
  color: var(--highlight-font-color);
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  max-width: 55%;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  margin: 0 0.5rem 0.5rem 0;
}

.filter-nibblet.mainFilter {
  max-width: 100%;
  margin-left: 0.5em;
  margin-right: 0em;
  font-size: 0.8em;
  margin-top: 0;
  margin-bottom: 0;
  max-height: 1.75rem;
}

.filter-nibblet-surveylist {
  background-color:  var(--highlight-color);
  color: var(--highlight-font-color);
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  margin-left: 1rem;
  max-height: 1.75em; 
  box-shadow: 2px 2px 3px #CCC !important;
  font-size: 0.9rem;
}

.filter-nibblet-surveylist:hover {
  opacity: 0.8;
}

.filter-nibblet:hover {
  opacity: 0.8;
}

.categoryList {
  overflow-y: auto;
  height: calc(100vh - 20em);
}

.whitebox {
  background-color: #fff;
  border-radius: 13px;
  padding: 2rem 0rem;
  min-height: 75%;
  height: calc(100vh - 5rem);
}

.whitebox-plan {
  background-color: #fff;
  border-radius: 13px;
  padding: 2rem 0rem;
  min-height: 75%;
  height: max-content;
}

.padding-x-2 {
  padding-right: 2rem;
  padding-left: 2rem;
}

.padding-0 {
  padding: 0 !important;
}

.search-bar-input {
	outline: none;
}

.search-bar-input[type=search] {
	-webkit-appearance: textfield;
  appearance: textfield;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
}

.search-bar-input::-webkit-search-decoration,
.search-bar-input::-webkit-search-cancel-button {
        display: none; 
}

.search-bar-input[type=search] {
	background: transparent url(./assets/search-icon.png) no-repeat 9px center;
    border: solid transparent;
    border-width: 0px 0px 1px 0px;
	padding: 0px 10px 0px 32px;
    width: 55px;
	
	/* -webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px; */
	
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	transition: all .5s;
}

.search-bar-input[type=search]:focus {
	width: 130px;
    background-color: transparent;
    border: solid ;
    border-width: 0px 0px 1px 0px;
  border-color: #000;
  
	
	/* -webkit-box-shadow: 0 0 5px rgba(109,207,246,.5);
	-moz-box-shadow: 0 0 5px rgba(109,207,246,.5);
	box-shadow: 0 0 5px rgba(109,207,246,.5); */
}

.search-bar-input:focus::-webkit-input-placeholder { color:#999 !important; }
.search-bar-input:focus::-moz-placeholder  { color:#999 !important; }/* FF 4-18 */
.search-bar-input:focus::-moz-placeholder { color:#999 !important; } /* FF 19+ */
.search-bar-input:focus::-ms-input-placeholder { color:#999 !important; } /* IE 10+ */

/* .search-bar-input:-moz-placeholder {
	color: #999;
}

.search-bar-input::-webkit-input-placeholder {
	color: #999;
} */

#search-bar:not(.gantt-search) input[type=search] {
	width: 15px;
	padding-left: 10px;
	color: transparent;
	cursor: pointer;
}

#search-bar input[type=search]:hover {
	background-color: transparent;
}

#search-bar input[type=search]:focus {
	width: 150px;
	padding-left: 32px;
	color: #000;
	background-color: transparent;
	cursor: auto;
}

#search-bar input:-moz-placeholder {
	color: transparent;
}

#search-bar input::-webkit-input-placeholder {
	color: transparent;
}

.search-bar-open {
  box-shadow: inset 1px 1px  rgb(217 212 212);
}

.search-bar-open[type=search] {
	-webkit-appearance: textfield;
  appearance:  textfield;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
}

.search-bar-open[type=search] {
	background: url(./assets/search-icon.png) no-repeat 9px center;
  background-color: var(--background-color);
  border-radius: 10px;
  padding: 0px 0px 0px 32px;
  width: 100%;
  margin-left: -32px;
}

.search-bar-open:focus::-webkit-input-placeholder { color:#999 !important; }
.search-bar-open:focus::-moz-placeholder  { color:#999 !important; }/* FF 4-18 */
.search-bar-open:focus::-moz-placeholder { color:#999 !important; } /* FF 19+ */
.search-bar-open:focus::-ms-input-placeholder { color:#999 !important; } /* IE 10+ */

.search-bar-gantt[type=search] {
	-webkit-appearance: textfield;
  appearance: textfield;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
}

.search-bar-gantt[type=search] {
	background: url(./assets/search-icon.png) no-repeat 9px center;
  background-color: var(--background-color);
  border-radius: 10px;
  padding: 0px 0px 0px 32px;
  width: 70%;
  /* margin-left: -32px; */
}

.timeline-box{
  height: 270px !important;
}
#timeline-chart{
  height: 210px !important;
}

.create-modal .box-header{
  background: #fff !important;
}

.continue-highlighted{
  background: var(--highlight-color) !important;
  font-weight: bold !important;
  color: #fff !important;
}

.texteditor-no-minHeight{
  min-height: 0em !important;
}

.active-button {
  background-color: var(--highlight-color);
}
.active-bold{
  font-weight: bold;
  color: #fff;
}

.enterprise-select-active{
  border: 1px solid var(--enterprise-primary-color);
  background-color: var(--enterprise-primary-color);
  color: #fff;
  font-weight: bold;

}

.bottom-border-for-header{
  margin-top: 0.5em;
  border-bottom: 1px solid var(--enterprise-primary-color);
}

.create-survey-from-dashboard-button{
  margin-top: 3em;
  width: 48%;
}
.dashboard-survey-create{
  min-height:10em;
}
.dashboard-survey-selector{
  width: 100%;
  padding:0 0.8em;
}

.list-selection-inner{
  padding: 0.5em;
}

.list-selection-item{
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-right: 0.5em;
  border-radius: 5px;
}

.list-selection-item:hover{
  background: #ebebeb;
}

.enterprise-box {
  border-radius: 10px;
  background-color: #fff;
  padding:0 0 1rem 0 ;
  margin-top: 1rem;
  box-shadow: 0 3px 6px #CCC;
}

.enterprise-list {
  padding: 0.5em 1em;
}

.enterprise-edit {
  padding-right: 1em;
  padding-left: 1em;
}

.enterprise-edit .row {
  padding-top: 1em;
  padding-bottom: 1em;
}

.enterprise-edit .row:nth-child(odd) {
  background-color: #F5F5F5;
}

.wide-button {
  padding-right: 2em;
  padding-left: 2em;
  white-space: nowrap !important;
}

.wide-button.active {
  background-color: #BDE533 !important;
  color: #FFF;
  font-weight: 700;
}

.wide-button.inactive {
  background-color: red !important;
  color: #FFF;
  font-weight: 700;
}

.input-bar {
  border: solid #999 !important;
  border-width: 0px 0px 1px 0px !important;
  padding: 0 !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.input-bar:focus {
  border: solid var(--highlight-color) !important;
  border-width: 0px 0px 1px 0px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.spinner-border.text-primary {
  color: var(--highlight-color) !important;
}

.spinner-border {
  border-width : .5em !important;
}

.survey-variables-container {
  position: relative;
  display: inline-block;
}

.add-variable-filter-button {
  display: inherit;
  padding: 0.5rem 1rem;
  background-color: var(--highlight-color);
  border-radius: 5px;
  cursor: pointer;
}

.add-variable-filter-button:hover {
  opacity: 0.8;
}

.survey-variables-select {
  width: 15rem;
  margin-left: 0.5rem;
  display: inherit;
  z-index: 10 !important;
}

.DateRangePicker {
  margin-right: 5px;
}

.DateRangePicker_picker {
  z-index: 10 !important;
}

.daterange-filter {
  position: absolute;
  padding: 1.5rem;
  background-color: #fff;
  z-index: 5;
  box-shadow: 0 3px 6px #CCC;
}

.filters-selections {
  display: flex;
  justify-content: space-between;
}

.mainFilterBar {
  position: absolute;
  z-index: 5;
}

.filters-main-selections {
  padding: 1.5rem 0 1.5rem 0;
  background-color: #fff;
  box-shadow: 0 3px 6px #CCC;
}

.mainFilterBar div {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.filters-main-selections div:hover {
  opacity: 0.8;
}

.filters-selections .filters-main-selections div:hover ~ .sideFilters {
  visibility: visible;
}

.mainFilterBar .sideFilters {
  visibility: hidden;
  padding: 1.5rem;
  background-color: #fff;
  z-index: 5;
  box-shadow: 0 3px 6px #CCC;
}

.sideFilters:hover {
  visibility: visible;
}

.breadcrumbs {
  margin-left: 6.5rem
}


.variable-group-container {
  margin: 1rem 0;
}

.formula-operator-select {
  width: 100%;
  display: inline-block;
}

.input-addon-button {
  background-color: var(--highlight-color);
  border-radius: 0 5px 5px 0;
  border: none;
  padding: 0.4rem 1rem;
  color: rgb(47, 47, 47);
}

.input-addon-button:hover {
  opacity: 0.8;
}

.plan-tabs{
  min-width: 100%;
}

.round-corners{
  border-radius: 5px;
}

.formula-test-result-label {
  background-color: #CCC;
  border-radius: 5px 0 0 5px;
  border: none;
  padding: 0.4rem 1rem;
  color: rgb(47, 47, 47);
}

.formula-container {
  border: 1px solid #CCC;
  border-radius: 5px;
  width: 100%;
  min-height: 100px;
  background-color: #f5f5f5;
  padding: 1rem;
}

.formula-block {
  border: 1px solid #CCC;
  border-radius: 5px;
  background-color: rgb(255, 255, 255, 0.4);
  color: #000;
  padding: 0.4rem 1rem;
  display: inline-block;
  margin: 0 0.2rem 0.5rem 0;
  text-align: center;
}

.custom-scrollbars > div {
  overflow-x: hidden !important;
  padding-bottom: 1.25rem;
}

.used-var-row {
  padding: 0rem 1rem 1rem;
}

.taskcard-text {
  margin: 0 0 1rem 0 !important;
}

.dot {
  text-align: center;
  height: 1.5em;
  width: 1.5em;
  background-color: rgb(255, 204, 0);
  color: #FFF;
  border-radius: 50%;
  display: inline-block;
  font-size: 1.2em;
}

.dashboard-dot{
  /* text-align: center; */
  height: 1em;
  width: 1em;
  border-radius: 50%;
  display: inline-block;
  font-size: 1rem;
  margin-top: 0.1em;
  font-size: 1.1rem;;
}

#business-name {
  font-size: 1.6rem;
}

/* Hashtag hilight*/
.draftJsHashtagPlugin__hashtag__1wMVC{
  color: red;
}

.summary-inner-container {
  padding-bottom: .5em;
}

.summary-inner-container:hover {
  box-shadow: 0 3px 6px 3px #CCC !important;
}

.edit-summary-card-cog:hover {
  opacity: .8;
}

.business-menu {
  position: absolute;
  right: 1.75em;
  top: 1.75em;
  width: 250px;
  height: 200px;
  z-index: 1;
}

.business-menu-content {
  width: 90%;
  background-color: #fff;
  box-shadow: 0 0 5px 1px #ccc;
  padding: .5em 0;
  text-align: center;
  display: none;
  height: 0;
  opacity: 0;
}

.plusIcon {
  font-size: 1.3rem;
  border-radius: 50%;
  background-color: var(--highlight-color);
  padding: 10px;
  color: #fff;
  align-self: center;
  cursor: pointer;
}

.dash-plusIcon {
  font-size: 1rem;
  border-radius: 50%;
  background-color:#CFE7EC;
  padding: 3px;
  cursor: pointer;
  align-self: center;
  box-shadow: 0 1px 2px #CCC;
}

.newPlanTarget-div {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newPlanTarget-div [class*='col-'] {
  padding-bottom: 10px;
}


.business-menu-item {
  padding: .5em;
  cursor: pointer;
}

.business-menu-item:hover {
  background-color: #999;
}

.business-menu-cog {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 1.4em;
}

.embed-fb-selector{
  margin-bottom: 1em;
}

.embed-hostname-textbox{
  margin-top: 1em;
}

.business-menu-cog:hover {
  opacity: .8;
}

.amcharts-Bullet, .amcharts-PointedCircle-group, .amcharts-RoundedRectangle-group {
  cursor: pointer;
}

.animated-filter-button {
  background-color: var(--highlight-color);
  border-radius: 5px;
  padding: 0.5rem 0.65rem;
  text-align: center;
  cursor: pointer;
  display: inline-block;
}

.animated-filter-item {
  padding: .5em 1em;
  cursor: pointer;
}

.animated-filter-item:hover {
  background-color: #CACACA;
}

.deleteApi:hover {
  background-color: red !important;
  color:#FFF !important;
  font-weight: 700;
}

.trello-label-row {
  width: 100%;
}

.trello-label {
  background-color: #b3bac5;
  border-radius: 4px;
  color: #fff;
  display: block;
  margin-right: 4px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: left;
  margin: 0 4px 4px 0;
  min-width: 60px;
  text-shadow: none;
  width: auto;
  height: 16px;
  line-height: 14px;
  padding: 0 8px;
  max-width: 198px;
  text-align: center;
}

.trello-label-text {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.trello-label-green {
    background-color: #61bd4f;
}

.trello-label-yellow {
    background-color: #f2d600;
}

.trello-label-orange {
    background-color: #ff9f1a;
}

.trello-label-red {
    background-color: #eb5a46;
}

.trello-label-purple {
    background-color: #c377e0;
}

.trello-label-blue {
    background-color: #0079bf;
}

.trello-label-black {
    background-color: #344563;
}

.trello-label-lime {
    background-color: #51e898;
}

.trello-label-pink {
    background-color: #ff78cb;
}

.trello-member {
  float: right;
  background-color: #dfe1e6;
  border-radius: 25em;
  color: #172b4d;
  cursor: pointer;
  display: block;
  margin: 0 4px 4px 0;
  overflow: visible;
  position: relative;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 0;
  height: 28px;
  line-height: 28px;
  width: 28px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.member-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checklist-numbers {
  font-size: 0.9em;
  opacity: 0.6;
  font-weight: 700;
  padding-left: 0.5em;
}

.planAttachment-name-overflow-handler{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modal-name-overflow-handler{
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20em;
  white-space: nowrap;
}


.tag-display{
  text-align: left;
  font: normal normal 9px/10px Raleway;
  letter-spacing: 0px;
  color: #292929;
  padding: 0.25em 0.5em 0.25em 0.5em;
  border-radius: 10px;
  opacity: 1;
  /* background: #AED7DE95; */
  margin-right: 0.25em;
}

.dashboard-active-selection{
  background: #7D7D7D;
  color: #fff;
}

.tag-text{
  align-self: center;
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 8em;
}

.excel-modal{
  max-height: 90vh;
}

.company-businessname{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drop-zone{
  min-height: 10em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  border: 1px dashed #969696;
  background-color: #eeeeee;
  opacity:0.8;
}
.drop-container{
  padding-bottom: 1em;
  cursor: pointer;
}

.comment-div{
  margin-left:0.75em !important;
}

.news-info{
  cursor:pointer;
  font-size: 1.2rem;
}

.news-info:hover{
  opacity: 0.8;
}

.tour-button{
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 99;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

/* .tourGuide-div{
 
} */

.tools-filename-overflower{
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 33vw;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  .modal-open { overflow:revert; } 
} 

@media screen and (min-width: 767px) {
  .kartoitusTool {
    margin-top:15px;
    margin-left: 33px;
    margin-right: 37px;
    margin-bottom: 15px;
    min-height: 90vh;
  }

}

@media screen and (max-width: 767px) {
  .categoryList {
    padding-left: 4vw !important;
    padding-right: 1vw !important; 
    height: calc(100vh - 20em);
  }
  body {
    overflow:auto;
  }
}



@media screen and (max-width: 768px) {
  .big-bold {
    font-size: 1.5rem !important;
    font-weight: bold;
  }
}

@media screen and ( max-width: 1024px ) {
  .big-bold {
    font-size: 2.5rem;
    font-weight: bold;
  }

  .large-score-ball {
    width: 80px !important;
    height: 80px !important;
    padding-top: 5px !important;
    font-size: 2em !important;
  }

  .survey-title-container {
    margin-left: 40px !important;
    padding-left: 50px !important;
  }

  .medium-score-ball {
    height: 60px !important;
    width: 60px !important;
    font-size: 1.5em !important;
    padding-top: 3px !important;
  }
  .questiongroup-title-container {
    margin-left: 30px !important;
    padding-left: 50px !important;
  }

  .questiongroup-main-row {
    margin-left: -30px !important;
  }

  .small-score-ball {
    height: 50px !important;
    width: 50px !important;
    font-size: 1.3em !important;
    padding-top: 2px !important;
  }

  .question-title-container {
    margin-left: 25px !important;
    padding-left: 40px !important;
  }

  .question-main-row {
    margin-left: -25px !important;
  }

  .report-button {
    float: left !important;
  }
  .report-button-container {
    margin-top: .5em;
  }
}

@media screen and (max-width: 1400px) {
  .navigation-bar .nav-item a {
    font-size: 1.1em;
  }

  .draggable-item{
    font-size: 0.8rem !important;
  }
  .media-smaller-text{
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 1921px) {
  .plan-panel{
    max-width:14em;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .summary-container.col-xl-3 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
