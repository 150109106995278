.project-container{
   display: flex;   
}

.project-data-label{
    font-weight: bold;
    margin-bottom: 0.5em !important;
    font-size: 1rem;
}

.project-data-row{
    margin-bottom: 1em;
    padding-bottom: 0.5em;
    /* border-bottom: 1px solid #40404040; */
}

.provider-item{
    background-color: #ccc;
    font-weight: bold;
    border-radius: 10px;
    padding: 0em 0.5em;
    margin-right: 0.5em;
    /* margin-top: 0.5em; */
    margin-bottom: 0.25em;
    font-size: 0.9rem;
    /* color: #fff; */
}

.sorting-hover:hover{
    font-weight: bold;
}

.progress-bar-base{
    background-color:#ebebeb;
    height: 1em;
    width:100%;
    /* margin-top: 0.25em; */
    margin-left: 1.4em;
    margin-right: 2.4em;
    /* border-radius: 10px; */
    display: flex;
}

.project-colored-box{
    border-radius: 10px;
    background-color: var(--project-color);
    /* padding-bottom: 1rem ; */
    margin-top: 1rem;
    box-shadow: 0 3px 6px #CCC;
}

.inner-project-container{
    background-color: var(--background);
    border-radius: 30px;
    padding-bottom: 1em;
}

#projectNameLabel{
    color: red ;
}

.project-description{
    margin-top: 0 !important;
}

.project-info-box{
    /* border-radius: 5px;
    box-shadow: 2px 2px 6px #ccc; */
    height: 70vh;
}

.box-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.project-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 100%;  
    flex-wrap: wrap;
    margin-top:1rem;
}

.form-control{
    border-color: #ddd !important;
}
.form-control[readonly] {
    background-color: #f2f2f2 !important;

}

.target-selected{
    background-color: var(--highlight-color);
    color:#fff;
    font-weight: bold;
}

.attachment-modal-search{
    border-bottom: 1px solid var(--highlight-color);
    margin-bottom: 1em;
}

.project-container{
    /* border-radius: 5px; */
    /* background-color: #FAFAFA; */
    box-shadow: 0 3px 6px #CCC;
    margin-bottom: 1em;
    min-width: 100%;
    /* overflow-x:hidden; */
}
.project-button{
    padding-right: 1em;
    padding-left: 1em;
}

.main-container{
    margin-top: 1em;
    min-height: 15em;
    display: flex;
    flex-direction: column;
}

.survey-selector{
    width: 100%;
}

.texteditor-container{
    padding: .375rem .75rem;
}

.modalContent{
    min-width: 30em;
    
}
.round-corners{
    border-radius: 5px;
}

/* a.active {
    border-color: #cccccc;
    border-width: 2px !important;
    border-bottom: 0px solid #fff !important;
} */

.project-header{
    display: flex;
    min-height: 2em;
    padding-bottom: 0.5em;
   
}

.project-divider{
    border-bottom: 1px solid var(--project-color);
    margin-left: 0.5em;
    margin-right: 1.5em;
}

.project-tag-in-header-bar{
    font-size: 0.8rem !important;
    font-weight: bold;
    background: #ebebeb !important;
    box-shadow: 1px 1px #ccc;
}

.project-tab-handler{
    margin-left: auto;
    margin-right: 2.5em;
    margin-top: 1em;
}
.DateRangePickerInput{
    border-radius: 5px;
}

.save-project{
    display:block;
    margin-bottom: 1em;
    width:100%;
    max-height: 3em;
}

.project-form{
    max-width: 50em;
    min-width: 50em;
}

.project-form-group{
    margin-left: 0;
    padding-left: 0 !important;
}

.project-list{
    min-width: 80%;
}

.texteditor-container{
    min-height: 10em;
}
.product-input{
    min-height: auto !important;
}

.project-nav-tabs{
    flex-wrap: nowrap;
    margin-right: 1em;
}

.project-statistic-div{
    display: flex; 
    align-items: center;
    justify-content: center;
}

.project-list-item{
    background-color: #fff;
    border-bottom: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
    border-top: 1px solid #cccccc; 
    border-right: 1px solid #cccccc;
    border-radius: 10px;
    margin-right: 0.75em;
    /* margin-bottom: 0.1em; */
    cursor: pointer;
    /* box-shadow: 1px 2px 3px rgb(130, 130, 130); */
}

.project-list-item:hover{
    border-bottom: 2px solid #999;
    border-left: 1px solid #999;
    border-top: 1px solid #999;
    border-right: 2px solid #999;
}

.plan-box{
    min-width: 50%;
    max-width: 50%;
}

Label {
    margin-bottom: 0;
}

.active-plan-selection{
    background-color: var(--highlight-color);
    font-weight: bold;
    color: #fff;
}

.list-project-name{
    font-weight: 600;
}

.list-project-target{
    font-size: 0.8rem;
}

.project-list{
    min-width: 15em;
    min-height: 15em;
}

.project-plan-cont{
    min-height: 32em;
}

.plan-list-name{
    font-weight: bold;
}
.plan-list-content{
    display: flex;
    justify-content: center;
}
.name-overflow-handler{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 9em;
    white-space: nowrap;
}

.name-overflow-handler-long{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20em;
    white-space: nowrap;
}
.project-title-overflow-handler{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70vw;
    white-space: nowrap;
}

.project-status-div{
    border: 2px solid #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 0.25em 0.5em;
    width: 6em;
    font-weight: bold;   
}

.project-status-div-from-top{
    padding: 0.5em 0.5em;
    margin-top: -1.47em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #e7e7e7;
    border-left: 2px solid #e7e7e7;
    border-right: 2px solid #e7e7e7;
    border-radius: 0 0 5px 5px;
    transform: perspective(10px) rotateX(-1deg);

}

.project-settings-button:hover{
    box-shadow: 2px 2px 3px rgb(188, 188, 188) !important;
    background-color: #ebebeb !important;
}

.tab-box{
    width: 100%;
    border-radius: 5px;
    background-color:var(--background);
    box-shadow: 2px 5px 12px #ccc;
    padding-bottom: 1em;
    height:100%;
    overflow: hidden;  
}

.tab-content{
    overflow-x: hidden;
    scrollbar-width: none;
}

.tab-header{
    /* border-bottom: 1px solid var(--highlight-color); */
    min-height: 55px;
    display: flex;
    align-content: center;
    padding: 1em;
}

.project-container .tab-header{
    padding-left: 0;
    padding-right: 0;
}

.selected-to-files{
    border: 2px solid var(--highlight-color);
    margin: -2px;
}

.attachment-group-header{
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
    margin-bottom: 0.5em;
}

.attachment-info{
    border: 2px solid var(--highlight-color);
    padding: 0.25em 0.5em 0.25em 0.5em;
    border-radius: 50%;
    cursor: pointer;
}
.attachment-info:hover{
    opacity: 0.9;
    box-shadow: 0 2px 5px var(--highlight-color);
}
.project-filter-nibblet{
    background-color: var(--highlight-color);
}

/* react-select custom option styles */

.option-label{
    font-weight: 600;
}
.option-tag-container{
    display: flex;
    flex-direction:row;
    margin-right: 0;
    margin-left: auto;
}
.option-tag{
    text-align: left;
    font: normal normal 10px/11px Raleway;
    letter-spacing: 0px;
    color: #292929;
    padding: 0.25em 0.5em 0.25em 0.5em;
    border-radius: 10px;
    opacity: 1;
    background: #AED7DE95;

    margin-right: 0.25em;
    align-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 8em;
}
.react-select__control {
    border: 1px solid #ddd !important;
    border-radius: 5px !important;
    box-shadow: none !important;
}
.tag-row{
    display: flex;
    flex-direction: row;
}

.project-description-container{
    max-height: 400px;
    overflow:auto;
}

/* .project-options{
    font-size: 1.5rem;
    cursor: pointer;
    float: right;
    margin-top: -1em;
} */

.plan-list-sorter-active{
    background-color: #6ba53f;
}
.plan-list-sorter-active:hover{
    background-color: #6ba53f !important;
    opacity: 0.8;
}

/*******************************************/
/**************** VIEW *********************/
/*******************************************/

.compare-box{
    padding: 1em 2em 1em 2em;
    /* box-shadow: 0px 3px 4px 0px #ccc; */
    border-radius: 0px 0px 5px 5px  !important;
    display:flex;
    flex-direction: column;
    overflow:auto;
}

.view-excel-block{
    width: 20em;
}

.summary-row{
    /* border-bottom: 1px solid rgba(204, 204, 204, 0.5);*/
    /* border-top: 2px solid rgb(0, 0, 0);  */
    box-shadow: 0px 3px 4px 0px #ccc;
    background-color: rgba(184, 184, 184, 0.623); 
    border-radius: 5px !important;
    font-weight: bold;
    height:100%;
}
.summary-header{
    padding-top: 1.25em;
    padding-bottom: 1.25em;   
    margin-right: 1em;
}

.topic-header-row{
    padding-top: 0.5em;
    padding-bottom: 0.5em; 
    margin-right: 2em;
    height: 3.25em;
}

.group-header{
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
}

.center-in-compare{
   display: flex;
   justify-content: center;
   align-content: center;
}

.group-row{
    /* box-shadow: 0 3px 6px #CCC; */
    /* border-bottom: 1px solid rgba(204, 204, 204, 0.5); */
    border-radius: 5px !important;
    
}
.center-topic{
    display: flex;
    /* justify-content: center; */
    align-content: center;
}

.view-text-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    word-break: break-all;
}

.group-header-row{
    /* border-bottom: 1px solid rgba(184, 184, 184, 0.1); */
    /* border-top: 1px solid rgb(255, 255, 255);
    margin-top:-1px; 
    margin-bottom: -1px; */
    box-shadow: 0px 3px 4px 0px #ccc;
    background-color: rgba(184, 184, 184, 0.103);
    border-radius: 5px !important;
    cursor: pointer;
    font-weight: bold;
}

.collapse-section{
    padding-left: 1em !important;
    padding-right: 1em !important;
}

.business-buttons{
    /* padding-bottom: 1em;   */
    border-radius: 10px 10px 0 0 !important;
    box-shadow: 0px 3px 4px 0px #ccc;
    display:block;
}

.selected-excel{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 0em 0.25em 0.5em 0.25em; 
    font-size: 1.25rem;
    width: 100%;
    /* height: 5em; */
    font-weight: bold;
    cursor:default;
    
}

.remove-from-view-button{
    align-self: flex-end;
    cursor: pointer;
}

.compare-first{
    background-color: #D1F8C536;
    margin-right: 1em;
    border-left:4px solid #B8DE33;
    border-right:4px solid #B8DE33;
    margin-left: -1em;
}

.compare-middle{
    background-color: #F8F4C836;
    margin-right: 1em;
    border-left:4px solid #F8F13D;
    border-right:4px solid #F8F13D;
}

.compare-last{
    background-color: #F7C2BD36;
    margin-right: 1em;
    border-left:4px solid #EF5050;
    border-right:4px solid #EF5050;
}

.darker-compare-first {
    background-color: #D1F8C586;
    margin-right: 1em;
    box-shadow: 0px 3px 4px 0px #ccc;
    border-left:4px solid #B8DE33;
    border-right:4px solid #B8DE33;
}

.darker-compare-middle{
    background-color: #F8F4C886;
    margin-right: 1em;
    box-shadow: 0px 3px 4px 0px #ccc;
    border-left:4px solid #F8F13D;
    border-right:4px solid #F8F13D;
}

.darker-compare-last{
    background-color:#F7C2BD86;
    margin-right: 1em;
    box-shadow: 0px 3px 4px 0px #ccc;
    border-left:4px solid #EF5050;
    border-right:4px solid #EF5050;
}

.dark-compare-first {
    background: transparent linear-gradient(0deg, #B8DE33 0%, #D7F07D 100%) 0% 0% no-repeat padding-box;
    margin-right: 1em;
    box-shadow: 0px 3px 4px 0px #ccc;
}

.dark-compare-middle{
    background: transparent linear-gradient(0deg, #F8F13D 0%, #FAF78B 100%) 0% 0% no-repeat padding-box;
    margin-right: 1em;
    box-shadow: 0px 3px 4px 0px #ccc;
}

.dark-compare-last{
    background: transparent linear-gradient(0deg, #EF5050 0%, #F57070 100%) 0% 0% no-repeat padding-box;
    margin-right: 1em;
    box-shadow: 0px 3px 4px 0px #ccc;
}

.dark-bottom-first{ 
    background:  #B8DE33;
    margin-right: 1em;
    box-shadow: 0px 3px 4px 0px #ccc;
}
.dark-bottom-middle{
    background:  #F8F13D;
    margin-right: 1em;
    box-shadow: 0px 3px 4px 0px #ccc;
}
.dark-bottom-last{
    background: #EF5050;
    margin-right: 1em;
    box-shadow: 0px 3px 4px 0px #ccc;
}

.viewExcels{
    width: 100%;
    padding-right: 1em;
}
.selected-excel-block{
    margin-right: 0.25em;
   
}

.view-options{
    padding: 0em 0.5em 0em 0.5em;
    cursor: pointer;
    /* margin-left: auto;
    margin-right: 1em; */
    float: right;
    height: 2em;
}

.save-view-button{
    margin-left:auto;
    margin-right: 0;
}

.compare-box .addVideoButton{
    background: var(--enterprise-primary-color) !important;
}

.compare-box .addImageButton {
    background: var(--enterprise-primary-color) !important;
}

.group-header-row:hover{
    opacity: 0.8;
}



.modal-body{
    min-height: 10em;
}

.view-header-row{
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--enterprise-primary-color);
    margin-bottom: -1px;
    background-color: #fff;
    color: var(--enterprise-secondary-color);
    display: flex;
    flex-direction: row;
    padding-top: 2em;
    /* padding-bottom: 1em; */
}

.business-name-handler{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    max-width:10em;
}

.value-header{
    padding-top:0.75em;
    padding-bottom: 0.25em;
    font-size: 0.9rem;
    font-weight: bold;
}

.view-centered{
    justify-content: center;
    display: flex;
    align-items: center;
}
.compare-row-divider{
    border-top: 1px solid rgba(0,0,0,0.1);
    margin-bottom:-1px;
    margin-left: 1em;
    margin-right: 1em;
}
.compare-topic-divider{
    border-top: 1px solid rgba(0,0,0,0.1);

}

.bottom-elements{
    height: 1em;
    border-radius: 0 0 10px 10px !important;
    box-shadow: 0px 3px 4px 0px #ccc;
}

.tag-container{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.selected-calc-active{
    background-color: var(--highlight-color);
    color:#fff;
    font-weight: bold;
}

.limit-text-length{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20em;
    /* white-space: wrap; */
    /* line-height: 1.25em; */
    max-height: 3.25em;
    display:inline-block;
    text-align: center;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.remove-row{
    padding: 0 0.5em 0 0;
}