.kartoitusBuilder {
    margin-top: 50px;
    margin-left:15px;
    height: calc(100vh - 55px);
    background-color: #FFF;
    overflow: auto;
}

.dataWindowSidePanel {
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: #F8F8F8;
    border-top-left-radius: 10px;
    height: inherit;
}

.kartoitusSidePanelFull {
    height: inherit;
}

.topPanel {
    padding-bottom: 20px;
}

.categoryList-survey{
    height: calc(100vh - 17em)
}

.surveyListContent {
    /* height: 100%; */
    overflow-y: auto;
}
.kartoitusSidePanelFull .col {
    width: 100%;
    flex: 0 auto;
    padding-left: 0;
    padding-right: 0;
}

.kartoitusSidePanelFull .col p {
    padding-left: 25px;
    margin-bottom: 0px;
}

.kartoitusSidePanelFull .row {
    padding-left: 0px;
    padding-right: 15px;
    margin-left: 0;
}

.kartoitusSidePanel .col {
    width: 100%;
    flex: 0 auto;
    padding-left:0;
}

.kartoitusSidePanel .col p {
    padding-left: 25px;
    margin-bottom: 0px;
}

.kartoitusSidePanel .row {
    padding-left: 0px;
    padding-right: 15px;
    margin-left: 0;
}

.QuestionGroupEdit {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.question-picture {
    background-image: url('../../../../../assets/robotface.png');
    background-size: 45% 70%;
    background-repeat: no-repeat;
    background-position: center;
}
  
.picture-circle {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    box-shadow: 0 3px 6px #D6D6D6;
    display: inline-block;
}

.questionTabs .tab-content {
    padding-top: 1rem;
}

.questionEdit.question-Group .questionTabsRow .col {
    width: 100%;
}

.nav.nav-tabs .nav-item .questionTab {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-left: 2px solid #EBEBEB;
    border-top: 2px solid #EBEBEB;
    border-right: 2px solid #EBEBEB;
    border-bottom: solid transparent;
}

.analyzePoints {
    font-size: 45px;
}

.nav.nav-tabs .nav-item .questionTab:hover {
    background-color: var(--highlight-color) !important;
    font-weight:700;
    color: #404040 !important;
}

.nav.nav-tabs .nav-item .questionTab.selected {
    background-color: var(--highlight-color) !important;
    font-weight:700;
    color: #404040 !important;
}

.nav.nav-tabs .nav-item.questionTabItem {
    width: 200px;
}
.dataWindow {
    background-color: #FFF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* height: 100%; */
}

.selectedSurveyTitle {
    padding-top: 2rem;
    padding-left: 2rem;
}

.selectedSurveySubTitle {
    font-size: 20px;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;
}

.selectedSurveySubTitle.active {
    background-color: var(--highlight-color) !important;
}


.selectedSurveySubTitle:hover {
    background-color: var(--highlight-color) !important;
}

.kartoitusSidePanel {
    height: calc(100vh - 20rem);
    overflow-y: auto;
    overflow-x: hidden;
}

.question-type-selector {
    padding: 1rem;
    border: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
}

.question-type-selector-disabled {
    padding: 1rem;
    border: 1px solid #ccc;
    text-align: center;
    cursor: not-allowed;
}

.question-type-selector-active {
    padding: 1rem;
    border: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
    background-color: var(--highlight-color);
}

/* a.active {
    border-color: var(--highlight-color) ;
    border-width: 2px !important;
    border-bottom: 0px solid #fff !important;
} */

.survey-list-content {
    padding: 0.1em;
}

.copy-group {
    float: left;
    font-size: 1.2em;
}

.group-arrow:hover .move-down {
    color: #FFF;
}

.group-arrow:hover .move-up {
    color: #FFF;
}

.copy-icon {
    padding: 0em 0.3em 0em 0.3em;
    cursor: pointer;
    font-size: 1.2em;
}

.copy-icon:hover {
    color: #FFF;
}

.survey-list-content p {
    color: #404040;
}

.sidePanelGroup {
    padding-bottom: 20px;
}
.sidePanelTitle {
    font-weight:700;
    cursor: pointer;
    padding-bottom: 5px;
    word-wrap: anywhere;
}

.create-new-button {
    box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);
    border-radius: 5px;
    background-color: #fff;
    border: none;
    padding: 0.55rem;
}

.sidePanelTitle.active {
    background-color: var(--highlight-color) !important;
}

.sidePanelSubTitle {
    line-height: 1.2em;
    cursor: pointer;
    padding: 0 0 .5rem 2rem;
}

/* .subTitleSelector {
     padding: 0 0 .3rem 0; 
} */

.swb-hover:hover {
    background-color: #cecece
}

.analyzerTextArea, .group-text-area{
    height: 5rem;
}

.multi-selector span svg {
    color: var(--highlight-color) !important;
}

.multi-selector button svg {
    color: #666666 !important;
}

.groupHighLighted {
    background-color: var(--highlight-color) !important;
}

.analyzer-color-row div{
    cursor: unset;
}

.group-arrow {
    padding: 0em 0.3em 0em 0.3em;
    cursor: pointer;
    float: right;
    font-size: 1.2em;
}   

.selectedSurveySubTitle_arrow {
    font-size: 20px;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
	background: var(--highlight-color);
}

.selectedSurveySubTitle_arrow:after, .selectedSurveySubTitle_arrow:before {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.selectedSurveySubTitle_arrow:after {
	border-color: rgba(136, 183, 213, 0);
	border-left-color: var(--highlight-color);
	border-width: 10px;
	margin-top: -10px;
}
.selectedSurveySubTitle_arrow:before {
	border-color: rgba(194, 225, 245, 0);
	border-left-color: var(--highlight-color);
	border-width: 14px;
	margin-top: -14px;
}

.react-select__control {
    border: 1px solid #ddd !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}

.info-order-list li {
    padding-top: 5px;
}

.infoIcon {
    padding-left: 1em;
    font-size: 1.3em;
    cursor: pointer;
    color: var(--highlight-color);
}

.infoContainer {
    padding-bottom: 0.5em;
    align-items: center;
    display: flex;
}

.react-select__control--is-focused {
    outline: none !important;
}

.customPointsPicker.row .col i.material-icons {
    font-size: 45px !important;
    user-select: none;
}

.customPointsPicker.row .col p {
    font-size: 45px;
    cursor: pointer;
}

.customPointsPicker.row {
    align-items: center;
}

.customPointsPicker .col .material-icons {
    cursor: pointer;
}

.subGroupTitleSelector {
    padding: .3rem;
}

.subTitleSelector.active, .subGroupTitleSelector:hover, .subGroupTitleSelector.active, .subTitleSelector:hover {
    background: var(--highlight-color);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; 
}

.tomHanksEditor .texteditor-container {
    height: 300px;
}

.explanationEditor .texteditor-container {
    height: 300px;
}

.arrow_box:after {
	border-color: rgba(136, 183, 213, 0);
	border-left-color: var(--highlight-color);
	border-width: 10px;
	margin-top: -10px;
}
.arrow_box:before {
	border-color: rgba(194, 225, 245, 0);
	border-left-color: var(--highlight-color);
	border-width: 14px;
	margin-top: -14px;
}

.categoryEditWindow {
    padding-top: 2rem;
}

.customPointsPickerInput {
    border: none !important;
    font-size: 25px !important;
    padding-bottom:5px !important;
}

.tagAddButton {
    background-color:#FFF !important;
    color:#404040 !important;
    border: 1px solid var(--highlight-color) !important;
}

.tagAddButton:hover {
    background-color: var(--highlight-color) !important;
    color: #FFF !important;
    font-weight: 700;
    border: transparent;
}

.deleteButton {
    float: right;
    background-color: transparent !important;
    color: red !important;
    border: none !important;
}

button.addOption.btn.btn-secondary {
    background-color: #FFF;
    color: #404040;
    border: 2px solid var(--highlight-color);
}

button.addOption.btn.btn-secondary:hover {
    background-color: var(--highlight-color) !important;
    color: #FFF !important;
    font-weight: 700;
}

.questionEdit {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.customNumberValues {
    font-size: 45px;
    float: left;
    width: 11rem;
}

.questionEditTopLevel .col {
    width: 100%;
}

.customPlusMinus i {
    cursor: pointer;
}

.customPlusMinus {
    float: left;
    margin-left: 1rem;
    margin-top: .75rem;
    font-size: 1.4rem;
}

.questionTabsRow .tabSelectors.nav-tabs {
    border: none;
    border-bottom: 2px solid var(--highlight-color);
} 

.icon-box {
    position: relative;
    text-align: center;
    border: solid thin #7d7d7d;
    border-radius: 0.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-bottom: 1em;
}

.icon-text {
    font-size: 0.4em;
    text-align: left;
}

.blur {
	color: rgba(0, 0, 0, 0);
	opacity: 0.3;
}

.rfip {
    margin: 0em 0 2em !important;
}

.rfipbtn__current {
    margin-right: 0.5em !important;
}

.builder-dropdown-toggle {
    float: right;
    margin-right: .5rem;
    padding-top: 5px;
    cursor: pointer;
}

/*MEGALOADERSPINNER*/

.loadingSpinnerRow {
    z-index: 9999;
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: all;
}

.loadingSpinnerRowChart {
    z-index: 9999;
    width: 100%;
    height: 100%;
    pointer-events: all;
}

.megaLoaderSpinner {
    align-self: center;
    position: fixed;
}
/*LOADING SPINNER*/


.loadingContainer.flex {
    position:fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingContainer {
        padding: .5rem;
        height: 100vh;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.03);
        border-radius: 4px;
      
        color: #84613D;
        font-family: "Lucida Console", Monaco, monospace;
        background: #FDF9EA;
        border-bottom: 1px solid #F9F2D6;
        border-right: 1px solid #F9F2D6;
}

.loadingContainer .loadingSpinner {
    text-align: center;
    width: 100%;
}


/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  /* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
  }
  
  /* :not(:required) hides these rules from IE9 and below */
  .loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  
  .loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  }
  
  /* Animation */
  
  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }