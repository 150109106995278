.addColor {
    background: #FFF;
    display: inline-block;
  }
  
  .addColorPopover {
    margin-top: 10px;
    background: #FFF;
    position: absolute;
    top: 0;
    /* right: 3.5rem; */
    border-radius: 2px;
    padding: 10px;
    box-shadow: 0px 4px 10px 0px rgba(220, 220, 220, 1);
    z-index: 1000;
  }
  
  .addColorClosedPopover {
    display: none;
  }
  
  .addColorButton, .addColorPressedButton {
    box-sizing: border-box;
    background: var(--highlight-color);
    border: none;
    color: #fff;
    margin: 0.5rem;
    border-radius: 50%;
    cursor: pointer;
    height: 2.1em;
    width: 2.1em;
    font-size: 1rem;
    line-height: 1.2em;
    position: absolute;
    top: -2rem;
    right: 4.5em;
    box-shadow: 0px 1px 3px 0px rgb(169, 169, 169);
    overflow: hidden;
  }
  
  .addColorButton:focus {
    outline: 0; /* reset for :focus */
  }
  
  .addColorButton:hover::before {
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    display: inline-block;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    padding: 0.5rem 0;
  }
  
  .addColorButton:active {
    background: #e6e6e6;
  }
  
  .addColorPressedButton {
    background: #ededed;
  }
  
  .addColorBottomGradient {
    width: 100%;
    height: 1em;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  }
  
  .addColorInput {
    border: 1px solid #ddd;
    padding: 4px;
    width: 100%;
    border-radius: 5px;
    margin-bottom: .5em;
  }
  
  .addColorConfirmButton {
    margin: auto;
    display: block;
  }
  
  .addColorConfirmButton:focus {
    outline: 0; /* reset for :focus */
  }
  
  .addColorConfirmButton:hover {
    background: #f3f3f3;
  }
  
  .addColorConfirmButton:active {
    background: #e6e6e6;
  }

  .color-square{
    height: 1.5em;
    width: 1.5em;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 0.25em;
    margin-bottom: 0.25em;
  }
