.block-with-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical; 
}

.draggable-item{
    border-bottom: 1px solid var(--highlight-color);
    background-color: #FFFFFF;
  }
  .draggable-item :hover{
    color: #FFFFFF;
    background-color:var(--highlight-color);
    cursor: -webkit-grab; cursor:-moz-grab; cursor:grab;
  }
  .draggable-shadow:hover{
    box-shadow: 0 3px 6px #CCC;
    opacity: 0.7;
  }