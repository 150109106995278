.addImage {
  background: #FFF;
  display: inline-block;
}

.addImagePopover {
  margin-top: 10px;
  background: #FFF;
  position: absolute;
  top: 0;
  /* right: 3.5rem; */
  border-radius: 2px;
  padding: 10px;
  box-shadow: 0px 4px 10px 0px rgba(220, 220, 220, 1);
  z-index: 1000;
}

.addImageClosedPopover {
  display: none;
}

.addImageButton, .addImagePressedButton {
  box-sizing: border-box;
  background: var(--highlight-color);
  border: none;
  color: #fff;
  margin: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  height: 2.1em;
  width: 2.1em;
  font-size: 1rem;
  line-height: 1.2em;
  position: absolute;
  top: -2rem;
  right: 2.35em;
  box-shadow: 0px 1px 3px 0px rgb(169, 169, 169);
  overflow: hidden;
}

.addImageButton:focus {
  outline: 0; /* reset for :focus */
}

.addImageButton:hover::before {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  display: inline-block;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  padding: 0.5rem 0;
}

.addImageButton:active {
  background: #e6e6e6;
}

.addImagePressedButton {
  background: #ededed;
}

.addImageBottomGradient {
  width: 100%;
  height: 1em;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
  pointer-events: none;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}

.addImageInput {
  border: 1px solid #ddd;
  padding: 4px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: .5em;
}

.addImageConfirmButton {
  margin: auto;
  display: block;
}

.addImageConfirmButton:focus {
  outline: 0; /* reset for :focus */
}

.addImageConfirmButton:hover {
  background: #f3f3f3;
}

.addImageConfirmButton:active {
  background: #e6e6e6;
}