.product-container{
    border-radius: 5px;
    background-color: #FAFAFA;
    box-shadow: 0 3px 6px #CCC;
}

.main-container{
    margin-top: 1em;
}

.product-box{
    margin: 1em;
    min-width: 60%;
}
.product-box-body{
    min-height: 15em;
    display: flex;
    flex-direction: column;
}

.react-select__control {
    border: 1px solid #ddd !important;
    border-radius: 5px !important;
    box-shadow: none !important;
}

.product-name{
    font-weight: 600;
}
.product-description{
    font-size: 0.9em;
}
.product-price{
    min-height: auto;
    border-radius: 4px !important;
    border: 1px solid #ddd !important;
}
.plan-input {
    border: 0 !important;
    min-height: auto;
    cursor: text;
}