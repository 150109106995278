.survey-summary-row{
   
    border-radius: 10px;
}

.survey-summary-row:hover{
    background-color: #ebebeb;
}

.bottom-border{
    border-bottom: 1px solid #404040;
    margin-bottom: 0.5em;
    margin-top: 0.25em;
}