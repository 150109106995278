.embed-edit-box {
   padding: 1em;
}

.embed-edit-box .row {
    margin-bottom: 1em;
}

.embed-list {
    padding: 1em 0em;
    cursor: pointer;
}

.active-embed {
    background-color: #DEDEDE;
}

.embed-selector {
    text-align: center;
}

.browser-box {
    width: 100%;
    padding-bottom: 56.25%;
    background-color:
    aliceblue;
    border-radius: 5px;
    background-image: url('../../assets/browser.png');
    background-size: cover;
}

.embed-text-area {
    min-height: 5rem;
    max-height: 11rem;
}

.embed-label {
    white-space: nowrap;
}

.left-square {
    position: absolute;
    bottom: 1em;
    left: 1em;
    cursor: pointer;
    font-size: 1.2em;
}

.right-square {
    position: absolute;
    bottom: 1em;
    right: 1em;
    cursor: pointer;
    font-size: 1.2em;
}

.center-square {
    position: absolute;
    top: calc(50% - 0.6em);
    left: calc(50% - 0.6em);
    cursor: pointer;
    font-size: 1.2em;
}

.inpage-square {
    position: absolute;
    top: calc(25% - 0.6em);
    left: calc(50% - 0.6em);
    cursor: pointer;
    font-size: 1.2em;
}

.fa-check-square {
    color: #B5E413
}

.sq-feedback {
    top: calc(50% - 0.6em) !important;
}

.copied {
    animation:blinkingText 0.4s;
    color: #B5E413;
    display: block;
}

.token:focus {
    box-shadow: none;
}

.link-content  {
    width: 100% !important;
    padding: 1em !important; 
}

@keyframes blinkingText{
	from {		color: transparent; opacity: 0;	}
	to{	color: #B5E413;	opacity: 1;}
}