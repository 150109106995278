.companySearch .col .row {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.companySearch .col:first-child {
    margin-top: 60px;
}

.activateButton button {
    width: 100%;
    height: 50px;
}

.client-colored-box{
    border-radius: 10px;
    background-color: var(--client-color);
    /* padding-bottom: 1rem ; */
    margin-top: 1rem;
    box-shadow: 0 3px 6px #CCC;
}

.inner-client-container{
    margin-top: 1em;
    background-color: var(--background);
    border-radius: 30px;
    padding-bottom: 1em;
}

.client-box-header{
    padding: 0 0 1rem 1rem ;
    margin-left: 0.25em;
}

.add-client-container{
    background-color: var(--background);
    border-radius: 0 30px 30px 0;
    padding-bottom: 1em;
    margin-top: 1em;
}

.add-client-in-modal{
    margin-top: 2em;
    width: 100%;
}

.YTunnusInput {
    border: none !important;
    border-bottom: 1px solid #404040 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.YTunnusInput:focus {
    border-color: inherit;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.YTunnusInput-correct {
    border: none !important;
    border-bottom: 1px solid #404040 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    color: #B5E413 !important;
}

.YTunnusInput-correct:focus {
    border-color: inherit;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.companyRegistrations {
    border: none !important;
    border-bottom: 1px solid #404040 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.companyRegistrations:focus {
    border-color: inherit;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.info {
    height: 25px;
    width: 25px;
    background-color: #B5E413 !important;
    border-radius: 50%;
    display: inline-block;
    margin-left: 0.8rem;
    text-align: center;
    color: #FFF;
    position: relative;
}

.info .tooltipForEmail {
    visibility: hidden;
    width: 320px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    top: -5px;
    right: 105%; 
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

.info:hover .tooltipForEmail {
    visibility: visible;
}

.YTunnusInput-false {
    border: none !important;
    border-bottom: 1px solid #404040 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    color: #ED5652 !important;
}

.YTunnusInput-false:focus {
    border-color: inherit;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.activateButton button.ready {
    background-color: #B5E413;
    color: #FFFFFF;
}

.collaboration {
    background-color: #FDFDFD;
    height:100%;
}

.addCompanyCircle {
    font-size: 5em;
    color: #749db4 !important;
    cursor:pointer;
}

.companyForm {
    height:0;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s linear;
}

.tableRowCompany {
    cursor: pointer;
}

.companyForm.newCompany {
    height: 100%;
    opacity: 1;
}

.company-box {
    border-radius: 5px;
    background-color: #fff;
    padding-bottom: 1rem;
    margin-top: 1rem;
    /* box-shadow: 0 3px 6px #CCC; */
}

.company-list {
      padding: 0.5em;
}


.multi-selector-compppany-register svg {
    color: #B5E413;
}

.contact-card-overview{
    margin-top: 2em;
    margin-left: -15px;
    margin-right: -15px;
}

.contacts-footer{
    background-color: var(--contact-color);
}

@media screen and (min-width: 1200px) {
    .add-client-container{
        border-left: 2px solid var(--client-color);
    }
}

@media screen and (max-width: 1200px) {
    .add-client-container{
        border-top: 2px solid var(--client-color);
        border-radius: 0 0px 30px 30px;
        padding-top: 1em;
        margin-left: 1em;
        margin-right: 1em;
    }
}
